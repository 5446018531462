import { Tabs } from "antd";
import React, { useState } from "react";
import {
  EditOutlined,
  LockOutlined,
  BankOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import ChangePassword from "../../../components/settings/changepassword";
import Sidebar from "../../../components/sidebar";
import SettingsNavBar from "./SettingsNavBar";
import "./settings.less";
import BankDetailsUpdate from "./bank-details-update/BankDetailsUpdate";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ClientProfile from "../../client/settings/clientProfile";
import BankDetails from "../../client/settings/bankDetails";
import { fetchBankDetails } from "../../../redux/profileDetails/profile.action";
import CaregiverProfile from "./edit-profile-update/caregiverProfile";
import Responsiveheader from "../../../components/Responsiveheader";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import CaregiverProfileAction from "../../../redux/profileDetails/profile.action";
import Footer from "../../../components/Footer/Footer";
import StripeVerify from "../StripeVerify";

const breadcrumbItemId = {
  1: {
    title: "Edit Profile",
    route: "",
  },
  2: {
    title: "Change Password",
    route: "",
  },
  3: {
    title: "Payment Information",
    route: "",
  },
};

const Settings = () => {
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [tabChange, setTabChange] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.loginData.userMode);
  // console.log("role",userMode)
  console.log(tabChange, "tabChnage");
  useEffect(() => {
    dispatch(fetchBankDetails(token));
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.settings.concat({
          title: "Edit Profile",
          route: "/",
        })
      )
    );
    dispatch(CaregiverProfileAction(token));
  }, []);
  const tabClick = (key) => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.settings.concat(breadcrumbItemId[key])
      )
    );
    setTabValue(key);
    setTabChange(!tabChange);
  };

  // },[])
  // const handleTabChange=()=>{
  //     setTabChange(!tabChange);
  // }

  return (
    <>
      <div style={{ background: "rgb(247, 249, 250)", minHeight: "100vH" }}>
        <div className="container-fluid setting-fluid">
          {isMobile ? (
            <div className="pt-5 d-flex pr-2">
              <div className="mx-4 w-100">
                <Responsiveheader title="Settings" />
                <div className="settings">
                  <Tabs
                    defaultActiveKey="1"
                    tabPosition="left"
                    size="large"
                    onChange={tabClick}
                    className={
                      tabValue == 1
                        ? "active1"
                        : tabValue == 2
                        ? "active2"
                        : tabValue == 3
                        ? "active3"
                        : "active4"
                    }
                  >
                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <EditOutlined style={{ color: "rgb(149 149 204)", fontSize: "18px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/editicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Edit Profile
                            <RightCircleOutlined
                              style={{ marginLeft: "77px" }}
                            />
                          </span>
                        </span>
                      }
                      key="1"
                    >
                      {/* <CaregiverUpdatedProfile/>
                                        <CompanionProfile/> */}
                      {userMode == "caregiver" ? (
                        <CaregiverProfile tabChange={tabChange} />
                      ) : (
                        <ClientProfile tabChange={tabChange} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <LockOutlined style={{ color: "blue", fontSize: "16px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/passwordicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Change Password
                            <RightCircleOutlined
                              style={{ marginLeft: "35px" }}
                            />
                          </span>
                        </span>
                      }
                      key="2"
                      onClick={() => {
                        setTabChange(2);
                        console.log(tabChange, "tabchange");
                      }}
                    >
                      <ChangePassword tabChange={tabChange} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <BankOutlined style={{ color: "green", fontSize: "15px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/bankicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Bank Information
                            <RightCircleOutlined
                              style={{ marginLeft: "15px" }}
                            />
                          </span>
                        </span>
                      }
                      key="3"
                    >
                      {/* <BankDetailsUpdate/> */}
                      {userMode == "caregiver" ? (
                        <BankDetailsUpdate tabChange={tabChange} />
                      ) : (
                        <BankDetails tabChange={tabChange} />
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          ) : (
            <div className="pt-5 d-flex">
              <Sidebar />
              <div className="mx-4 main-content w-100">
                {/* <SettingsNavBar /> */}
                <AppointmentNavBar />
                <div className="settings settings-caregiver">
                  <Tabs
                    defaultActiveKey="1"
                    tabPosition="left"
                    size="large"
                    onChange={tabClick}
                    className={
                      tabValue == 1
                        ? "active1"
                        : tabValue == 2
                        ? "active2"
                        : tabValue == 3
                        ? "active3"
                        : "active4"
                    }
                  >
                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <EditOutlined style={{ color: "rgb(149 149 204)", fontSize: "18px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/editicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Edit Profile
                            <RightCircleOutlined
                              style={{ marginLeft: "75px" }}
                            />
                          </span>
                        </span>
                      }
                      key="1"
                    >
                      {/* <CaregiverUpdatedProfile/>
                                        <CompanionProfile/> */}
                      {userMode == "caregiver" ? (
                        <CaregiverProfile tabChange={tabChange} />
                      ) : (
                        <ClientProfile tabChange={tabChange} />
                      )}
                    </Tabs.TabPane>

                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <LockOutlined style={{ color: "blue", fontSize: "16px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/passwordicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Change Password
                            <RightCircleOutlined
                              style={{ marginLeft: "35px" }}
                            />
                          </span>
                        </span>
                      }
                      key="2"
                    >
                      <ChangePassword tabChange={tabChange} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      className="tab"
                      tab={
                        <span>
                          {/* <BankOutlined style={{ color: "green", fontSize: "15px" }} /> */}
                          <span className="imageicons">
                            <img
                              src="/images/icons/bankicon.png"
                              alt=""
                              style={{ width: "16%" }}
                            />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Payment Information
                            <RightCircleOutlined
                              style={{ marginLeft: "17px" }}
                            />
                          </span>
                        </span>
                      }
                      key="3"
                    >
                      {/* <BankDetailsUpdate/> */}
                      {userMode == "caregiver" ? (
                        <BankDetailsUpdate tabChange={tabChange} />
                      ) : (
                        <BankDetails tabChange={tabChange} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      // style={tabValue == 3 ? { background: "#e6f8f3" } : {}}
                      tab={
                        <span>
                          {/* <BankOutlined style={{ color: "green", fontSize: "20px" }} /> */}
                          <span className="imageicons">
                            <img src="/images/icons/stripe-doc.svg" alt="" />
                          </span>
                          <span className="settingnames">
                            {" "}
                            Identity Verification
                            <RightCircleOutlined
                              style={{ marginLeft: "19px" }}
                            />
                          </span>
                        </span>
                      }
                      key="4"
                    >
                      {<StripeVerify tabValue={tabValue} hideButton={true} />}
                    </Tabs.TabPane>
                  </Tabs>
                </div>

                <Footer />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Settings;
