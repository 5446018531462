import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../../components/sidebar";
import AppointmentNavBar from "../../../../client/appointment/appointmentsNavBar";
import { Button, Col, Divider, Input, Modal, Radio, Row, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../../redux/alert/alert.action";
import "../../../../client/appointment/service-grid.less";
import moment from "moment";
import { paymentDetails } from "../../../../../redux/appointment/appointment.action";
import ScrollWrapper from "../../../../../components/ScrollWrapper/ScrollWrapper";
import ActionCreator from "../../../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../../../components/Footer/Footer";
import Responsiveheader from "../../../../../components/Responsiveheader";
import { CloseCircleOutlined } from "@ant-design/icons";
import AddCardDetails from "../../../../client/settings/addCardDetails";

export default function ExtendPaymentList(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addedCardsList, setCardsList] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [paySuccessVisible, setPaySuccessVisible] = useState(false);
  const [payUnsuccessVisible, setPayUnsuccessVisible] = useState(false);
  const [totalHours, setTotalHours] = useState(1);
  const [addCardPopUp, setAddCardPopUp] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const appointmentData = useSelector((state) => state.appointment);
  const extendBooking = useSelector((state) => state.appointment.extendBooking);
  console.log(extendBooking, "extendBooking");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const selectedCCId = useSelector((state) =>
    state.appointment.selectedCC?.caregiverId
      ? state.appointment.selectedCC?.caregiverId
      : state.appointment.selectedCC?._id
  );
  const selectedCC = useSelector((state) => state.appointment.selectedCC);
  const token = localStorage.getItem("token");
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  console.log(appointmentData, "appointmentData");
  const cardImage = {
    Discover: "/images/icons/discover.png",
    "American Express": "/images/icons/amex.png",
    MasterCard: "/images/icons/mastercard.png",
    UnionCard: "/images/icons/unioncard.png",
    Visa: "/images/icons/visa.svg",
    Diners: "/images/icons/diners.png",
    Jcb: "/images/icons/jcb.png",
  };
  const handleSuccessModalVisible = () => {
    setPaySuccessVisible(true);
  };

  const handleSuccessModalClose = () => {
    setPaySuccessVisible(false);
  };

  const fetchAddedCards = () => {
    const token = localStorage.getItem("token");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-card`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setCardsList(response.data.output.list);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handlePayment = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/pay-extend-appointment`,
          {
            bookingId: extendBooking?._id,
            cardId: selectedCard,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setPaySuccessVisible(true);
            dispatch(paymentDetails(response.data.output.result));
            // setCareGiverDetails(response.data.output)
            // dispatch(bookAppointment(response.data.output));
            // dispatch(selectedCC(caregiverDetails));
            // navigate("/client/appointment/pay")
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleCardChange = (e) => {
    setSelectedCard(e.target.value);
  };
  console.log(selectedCC, "selectedCC");
  useEffect(() => {
    const slotsArr = appointmentData?.dateTimeResp.slot;
    let hours = 0;
    slotsArr.forEach((i) => {
      const date = moment(i.date, "YYYY/MM/DD");
      const startTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(i.time?.startTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      let endTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(i.time?.endTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      if (i?.isOverNight) {
        endTimeObj =
          date.format("YYYY-MM") +
          "-" +
          moment(date).add(1, "days").format("DD") +
          "T" +
          moment(i.time?.endTime).utc(true).format("HH:mm") +
          `:00.000Z`;
      }

      const end = moment(endTimeObj);
      const start = moment(startTimeObj);
      let duration = moment.duration(end.diff(start));
      hours = duration.asHours();
    });
    setTotalHours(hours);
    fetchAddedCards();
    if (props.isBookmarked) {
      const breadCrumbArr = [...ActionCreator.BreadcrumbItems.caregivers];
      breadCrumbArr.push({
        title: selectedCC?.caregiverName
          ? selectedCC?.caregiverName
          : `${selectedCC?.firstName} ${selectedCC?.lastName}`,
        route: `my-caregivers/view-caregiver/${selectedCCId}`,
        isCustom: true,
      });
      breadCrumbArr.push({
        title: "New Appointment",
        route: "",
      });
      dispatch(ActionCreator.pushBreadcrumb(breadCrumbArr));
    } else {
      dispatch(
        ActionCreator.pushBreadcrumb(
          ActionCreator.BreadcrumbItems.newAppoinment
        )
      );
    }
  }, []);

  //  const handleResize = () => {
  //   setIsMobile(window.innerWidth < 768);
  // };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUnsuccessModalClose = () => {
    setPayUnsuccessVisible(false);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{isMobile?*/}
      {/*    <>*/}
      {/*/!* <div className="  "> *!/*/}
      {/*  <div className="p-3 pt-4 d-flex mx-2 w-100">*/}
      {/*              <Responsiveheader  title="Payment " />*/}
      {/*            </div>*/}

      {/*            <div className='p-3 border-less  mx-3 rounded' style={{background:"#fff", minHeight:"73vh"}}>*/}
      {/*           <div>*/}
      {/*           <p className='mt-2 fw-semibold fs-4 mb-3'>Payment</p>*/}
      {/*            <Spin */}
      {/*            spinning={loading} */}
      {/*            tip="loading...">*/}
      {/*            <div className="payment-wrapper" style={{width: "100%"}}>*/}
      {/*                <p className="payment-msg text-left">Your payment is only processed once the Caregiver/Companion accepts your request. Your funds remain safe in our trust account and only gets released after each appointment is completed.</p>*/}
      {/*                 <Row>*/}
      {/*                    <Col xl={24} lg={24}>*/}
      {/*                    <Row>*/}
      {/*                    <Col xl={12} lg={12} md={12}>*/}
      {/*                        <div className="amount-wrapper" >*/}
      {/*                        <div className="d-flex justify-content-between align-items-center">*/}
      {/*                        <p className="price-title">Caregiver Price</p> */}
      {/*                        <p className="price-value">${appointmentData?.selectedCC?.scheduleAgain ? appointmentData?.bookingDetails?.bookingDetails?.caregiverActualPrice : appointmentData?.selectedCC?.expectedRate}</p> */}
      {/*                        </div>*/}
      {/*                        <div className="d-flex justify-content-between align-items-center">*/}
      {/*                          <p className="price-title">Additional Fee & Taxes</p>*/}
      {/*                          <p className="price-value">*/}
      {/*                          {`(${appointmentData?.bookingDetails?.bookingDetails?.additionalTaxPercentage}%)`} ${appointmentData?.bookingDetails?.bookingDetails?.additionalTaxAmount}*/}
      {/*                          </p>*/}
      {/*                        </div>*/}
      {/*                        <Divider />*/}
      {/*                        <div className="d-flex justify-content-between align-items-center total">*/}
      {/*                        <p className="price-title">Total Amount</p> */}
      {/*                        <p className="price-value">${appointmentData?.selectedCC?.scheduleAgain ? (appointmentData?.bookingDetails?.bookingDetails?.caregiverTotalAmount).toFixed(2) : (totalHours * appointmentData?.selectedCC?.expectedRate).toFixed(2)}</p> */}
      {/*                        </div>*/}
      {/*                        </div>*/}
      {/*                    </Col>*/}
      {/*                    <Col xl={12} lg={12} md={12} className={"pt-3"}>*/}
      {/*                        <h3 className="card-select-text">Select Card</h3>*/}
      {/*                        {addedCardsList.length ===  0 ?*/}
      {/*                        <div className="no-cards-wrapper text-center p-3" style={{position:'relative', left:'10%'}}>*/}
      {/*                            <img src="/images/icons/no-cards.svg" />*/}
      {/*                            <Button className="add-card-btn btn-black auth-action-btn mt-3 mb-3" shape="round" type="primary" size="large" style={{margin: "auto"}} onClick={()=>{setAddCardPopUp(true)}}>*/}
      {/*                                Add Card*/}
      {/*                            </Button>*/}
      {/*                        </div>*/}
      {/*                        :*/}
      {/*                        < >*/}
      {/*                         <Radio.Group onChange={handleCardChange} value={selectedCard}>*/}
      {/*                                              */}
      {/*                            {addedCardsList.map(i => {*/}
      {/*                                return (*/}
      {/*                                    <Radio value={i?.id} className="card-radio-mobile-btn" style={{marginLeft:'-1rem'}}>*/}
      {/*                                <div className="payment-card-wrapper d-flex justify-content-between align-items-center" style={{width:'300px'}}>*/}
      {/*                                    <img src={cardImage[i?.brand]} width={60}/>*/}
      {/*                                    <span className="masked-number" style={{margin: '5.5px 30px 0 0'}}>****   {i?.last4} </span>*/}
      {/*                                  */}
      {/*                                </div>*/}
      {/*                            </Radio>*/}
      {/*                                )*/}
      {/*                            })}*/}
      {/*                        </Radio.Group> */}
      {/*                      </>*/}
      {/*                        }*/}
      {/*                    </Col>*/}
      {/*                    </Row>*/}
      {/*                    </Col>*/}
      {/*                </Row> */}

      {/*                </div>*/}
      {/*            <div className='d-flex justify-content-between px-3 mt-4'>*/}
      {/*            <Button */}
      {/*            // htmlType="submit"*/}
      {/*            onClick={() => {*/}
      {/*              if(props.isBookmarked) {*/}
      {/*                navigate("/my-caregivers/select-time-slots")*/}
      {/*              }*/}
      {/*              else {*/}
      {/*              navigate("/client/appointment/create-appointment/caregivers")*/}
      {/*              }*/}
      {/*            }}*/}
      {/*            className="btn-back auth-action-btn mt-3 mb-3" shape="round" size="large" style={{width:'100%'}}>*/}
      {/*                Back*/}
      {/*            </Button>*/}
      {/*            <Button */}
      {/*            // htmlType="submit"*/}
      {/*            onClick={handlePayment}*/}
      {/*            disabled={!selectedCard}*/}
      {/*            className="btn-black auth-action-btn mt-3 mb-3" shape="round" type="primary" size="large"  style={{width:'100%', left:'0.5rem'}}>*/}
      {/*                Pay*/}
      {/*            </Button>*/}
      {/*            </div> */}
      {/*              </Spin>*/}
      {/*        </div>*/}
      {/*           </div>*/}
      {/*           /!* </div> *!/*/}
      {/*</>:*/}

      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 message-inner-row w-100">
            <AppointmentNavBar />
            <div
              className="p-3 border-less rounded position-relative payment-list-main-div"
              style={{ background: "#fff", minHeight: "73vh" }}
            >
              <div>
                <p className="mt-2 fw-semibold fs-4 mb-3">Payment</p>
                <Spin spinning={loading}>
                  <div className="payment-wrapper" style={{ width: "100%" }}>
                    <p className="payment-msg text-left">
                      Your payment is only processed once the
                      Caregiver/Companion accepts your request. Your funds
                      remain safe in our trust account and only gets released
                      after each appointment is completed.
                    </p>
                    <Row>
                      <Col xl={24} lg={24}>
                        <Row>
                          <Col xl={12} xs={24} lg={12} md={24}>
                            <div className="amount-wrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="price-title">
                                  Caregiver Hourly Rate
                                </p>
                                <p className="price-value">
                                  $ {extendBooking?.caregiverActualPrice}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="price-title">
                                  Total Extended Hours
                                </p>
                                <p className="price-value">
                                  {`${extendBooking?.totalHour} Hours`}
                                </p>
                              </div>
                              <Divider />
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="price-title">Total</p>
                                <p className="price-value">
                                  ${extendBooking?.totalAmountWithoutTax}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="price-title">
                                  Taxes{" "}
                                  {`(${extendBooking?.additionalTaxPercentage}%)`}{" "}
                                </p>
                                <p className="price-value">
                                  ${extendBooking.additionalTaxAmount}
                                </p>
                              </div>
                              <Divider />
                              <div className="d-flex justify-content-between align-items-center total">
                                <p className="price-title mb-0">Total Amount</p>
                                <p className="price-value mb-0">
                                  ${extendBooking?.totalAmount}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            xl={12}
                            xs={24}
                            lg={12}
                            md={24}
                            className="payment-list-div mx-auto"
                          >
                            <h3 className="card-select-text">Select Card</h3>
                            {addedCardsList.length == 0 ? (
                              <div className="no-cards-wrapper text-center p-3">
                                <img src="/images/icons/no-cards.svg" />
                                <Button
                                  className="add-card-btn btn-black auth-action-btn mt-3 mb-3"
                                  shape="round"
                                  type="primary"
                                  size="large"
                                  style={{ margin: "auto" }}
                                  onClick={() => {
                                    setAddCardPopUp(true);
                                  }}
                                >
                                  Add Card
                                </Button>
                              </div>
                            ) : (
                              <ScrollWrapper height="calc(50vh - 100px)">
                                <Radio.Group
                                  onChange={handleCardChange}
                                  value={selectedCard}
                                >
                                  {addedCardsList.map((i) => {
                                    return (
                                      <Radio
                                        value={i?.id}
                                        className="card-radio-btn"
                                      >
                                        <div className="payment-card-wrapper d-flex justify-content-between align-items-center">
                                          <img
                                            alt={""}
                                            src={cardImage[i?.brand]}
                                            width={60}
                                          />
                                          <span
                                            className="masked-number"
                                            style={{ margin: "5.5px 30px 0 0" }}
                                          >
                                            **** **** ****
                                          </span>
                                          <p className="card-number">
                                            {i?.last4}
                                          </p>
                                          {/* <Input type="password" maxLength={3} className="cvv-input" /> */}
                                        </div>
                                      </Radio>
                                    );
                                  })}
                                </Radio.Group>
                              </ScrollWrapper>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Spin>
              </div>
              <div className="d-flex justify-content-between view-relative-details-inner px-mob-0 px-3 mt-4">
                <Button
                  // htmlType="submit"
                  onClick={() => {
                    navigate(
                      `/client/appointment/appointment-details/${appointmentData?.paymentDetails?._id}`
                    );
                  }}
                  className="btn-back auth-action-btn mt-3 mb-3"
                  shape="round"
                  size="large"
                >
                  Back
                </Button>
                <div className="d-flex align-items-center">
                  {addedCardsList.length > 0 && (
                    <Button
                      className="btn-black auth-action-btn mt-3 mb-3 me-3"
                      shape="round"
                      type="primary"
                      size="large"
                      onClick={() => {
                        setAddCardPopUp(true);
                      }}
                    >
                      Add New card
                    </Button>
                  )}
                  <Button
                    // htmlType="submit"
                    onClick={handlePayment}
                    disabled={!selectedCard}
                    className="btn-black auth-action-btn mt-3 mb-3"
                    shape="round"
                    type="primary"
                    size="large"
                  >
                    Pay
                  </Button>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*}*/}
      <PaymentSuccessModal
        paySuccessVisible={paySuccessVisible}
        handleSuccessModalClose={handleSuccessModalClose}
        isBookmarked={props.isBookmarked}
        isExtension={true}
      />
      <PaymentUnsuccessModal
        payUnsuccessVisible={payUnsuccessVisible}
        handleUnsuccessModalClose={handleUnsuccessModalClose}
      />
      <AddCardModal
        isMobile={isMobile}
        addCardPopUp={addCardPopUp}
        setAddCardPopUp={setAddCardPopUp}
        setCardsList={setCardsList}
        showCards={showCards}
        setShowCards={setShowCards}
      />
    </div>
  );
}

const PaymentSuccessModal = (props) => {
  const navigate = useNavigate();
  const { paySuccessVisible, handleSuccessModalClose, isExtension } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={paySuccessVisible}
      onOk={handleSuccessModalClose}
      onCancel={handleSuccessModalClose}
      maskClosable={false}
      closable={false}
    >
      <div className="image-container">
        <img src="/images/icons/info-group.svg" className="text-center" />
      </div>
      <h3 className="mt-3 fw-semibold fs-4 text-center">Payment Successful</h3>
      <p className="text-center mt-3">
        We have successfully put a hold on your credit card{" "}
      </p>
      <Button
        // htmlType="submit"
        onClick={() => {
          handleSuccessModalClose();
          if (isExtension) {
            navigate("/client/extend-appointment/booking-confirmation");
          } else {
            navigate("/client/appointment/booking-confirmation", {
              state: {
                isBookmarked: props.isBookmarked,
              },
            });
          }
        }}
        className="btn-black mx-auto auth-action-btn mt-3"
        shape="round"
        type="primary"
        size="large"
      >
        Done
      </Button>
    </Modal>
  );
};

const PaymentUnsuccessModal = (props) => {
  const { payUnsuccessVisible, handleUnsuccessModalClose } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={payUnsuccessVisible}
      onOk={handleUnsuccessModalClose}
      onCancel={handleUnsuccessModalClose}
      style={{ marginRight: "3rem" }}
    >
      <div className="image-container">
        <img
          src="/images/icons/payment-unsuccessful.svg"
          className="text-center"
        />
      </div>
      <h3 className="mt-3 fw-semibold fs-4 text-center">
        Payment Unsuccessful
      </h3>
      <p className="text-center mt-3">
        We were not able to put a hold on your credit card, please verify your
        card details and try again.
      </p>
      <Button
        // htmlType="submit"
        onClick={handleUnsuccessModalClose}
        className="btn-black mx-auto auth-action-btn mt-3"
        shape="round"
        type="primary"
        size="large"
      >
        Okay
      </Button>
    </Modal>
  );
};
const AddCardModal = (props) => {
  const {
    addCardPopUp,
    setAddCardPopUp,
    setCardsList,
    showCards,
    setShowCards,
    isMobile,
  } = props;
  return (
    <Modal
      className="info-modal-container "
      centered
      width={390}
      header={null}
      footer={null}
      open={addCardPopUp}
      closable
      closeIcon={<CloseCircleOutlined onClick={() => setAddCardPopUp(false)} />}
      onCancel={() => setAddCardPopUp(false)}
    >
      <div className="expenses_new_cardContainer">
        <h3
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Add New Card
        </h3>
        <AddCardDetails
          setCardDetails={setCardsList}
          setShowCards={setShowCards}
          removeHeader={false}
          onCancel={() => {
            setAddCardPopUp(false);
          }}
          setAddCardPopUp={setAddCardPopUp}
        />
        {/* {!isMobile&&<Button className="button_cancel"
            shape="round"
            size="large" 
            onClick={()=>{ setAddCardPopUp(false)}}
         >Cancel</Button> } */}
      </div>
    </Modal>
  );
};
