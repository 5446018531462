import {
  Button,
  Col,
  DatePicker,
  Divider,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "./appointmentsNavBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./service-grid.less";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/alert/alert.action";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  bookAppointment,
  selectedCC,
} from "../../../redux/appointment/appointment.action";
import moment from "moment/moment";
import { RatingModal } from "../../caregiver/appointment/appointmentDetails";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { Calendar } from "react-calendar";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import ScrollWrapper from "../../../components/ScrollWrapper/ScrollWrapper";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import { CloseCircleOutlined } from "@ant-design/icons";
import TransformImgWrapper from "../../../common/TransformComponent/TransformComponent";
// import "react-calendar/dist/Calendar.css";
// import DatePicker from "../../../common/DatePicker";
// import { RatingModal } from "../../caregiver/appointment/appointmentDetails";

const { Text } = Typography;
export default function CareGiverDetails(props) {
  const { isBookmarked } = props;
  const [loading, setLoading] = useState(false);
  const [caregiverDetails, setCareGiverDetails] = useState(null);
  const appointmentData = useSelector((state) => state.appointment);
  const { paymentDetails } = appointmentData;
  console.log(appointmentData, "appointmentData");
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [availabilityVisible, setAvailabilityVisible] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const routedData = useLocation();
  const unmatchedServices = routedData.state?.unmatchedServices || [];
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const fetchGiverDetails = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/view-caregiver`,
          {
            selectedServices: isBookmarked
              ? []
              : [
                  ...appointmentData?.servicesResp?.checkedCaregiver,
                  ...appointmentData?.servicesResp?.checkedCompanions,
                ],
            caregiverId: params?.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setCareGiverDetails(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    if (isBookmarked) {
      dispatch(
        ActionCreator.pushBreadcrumb(
          ActionCreator.BreadcrumbItems.caregivers.concat({
            title: caregiverDetails?.data?.firstName
              ? caregiverDetails?.data?.firstName
              : "New Appointment",
            route: `appointment-details`,
          })
        )
      );
    } else if (!isBookmarked) {
      dispatch(
        ActionCreator.pushBreadcrumb(
          ActionCreator.BreadcrumbItems.Appointments.concat({
            title: caregiverDetails?.data?.firstName
              ? caregiverDetails?.data?.firstName
              : "New Appointment",
            route: `appointment-details`,
          })
        )
      );
    }
  }, [params?.id, caregiverDetails?.data?.firstName]);
  console.log(params, "eee", caregiverDetails);

  const handleAddDeleteBookmark = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/add-bookmark`,
          {
            caregiverId: params.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            if (caregiverDetails?.data?.isBookmarked) {
              message.success("Bookmarked Removed Successfully!");
            } else {
              message.success("Bookmarked Added Successfully!");
            }
            fetchGiverDetails();
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleBookingAppointment = () => {
    if (token !== "") {
      setLoading(true);
      const slotsArr = [];
      appointmentData?.dateTimeResp.slot.forEach((i) => {
        const date = moment(i.date, "YYYY/MM/DD");
        const startTimeObj =
          date.format("YYYY-MM-DD") +
          "T" +
          moment(i.time?.startTime).utc(true).format("HH") +
          `:00:00.000Z`;
        let endTimeObj =
          date.format("YYYY-MM-DD") +
          "T" +
          moment(i.time?.endTime).utc(true).format("HH") +
          `:00:00.000Z`;
        if (i?.isOverNight) {
          endTimeObj =
            date.format("YYYY-MM") +
            "-" +
            moment(date).add(1, "days").format("DD") +
            "T" +
            moment(i.time?.endTime).utc(true).format("HH") +
            `:00:00.000Z`;
        }
        const obj = {
          date: date.format("YYYY-MM-DD") + "T00:00:00.000Z",
          time: { startTime: startTimeObj, endTime: endTimeObj },
        };
        // const startTime = i.time?.startTime;
        // const endTime = i.time?.endTime;
        // const startTimeObj = moment(startTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
        // const endTimeObj = moment(endTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
        slotsArr.push(obj);
      });
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/book-appointment`,
          {
            services: [
              ...appointmentData?.servicesResp.checkedCaregiver,
              ...appointmentData?.servicesResp.checkedCompanions,
            ],
            caregiverId: params?.id,
            slot: slotsArr,
            relativeId: JSON.parse(localStorage.getItem("relative"))?._id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            // setCareGiverDetails(response.data.output)
            dispatch(bookAppointment(response.data.output));
            dispatch(selectedCC(caregiverDetails.data));
            navigate("/client/appointment/pay-for-appointment");
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const handleReviewModalVisible = () => {
    setReviewModalVisible(true);
  };

  const handleRatingModalClose = () => {
    setReviewModalVisible(false);
  };

  useEffect(() => {
    fetchGiverDetails();
  }, []);

  const handleChooseRelative = () => {
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...caregiverDetails.data, scheduleAgain: true }));
    // dispatch({ type: CLEAR_DATA })
  };

  const handleAvailabilityVisible = () => {
    setAvailabilityVisible(true);
  };

  const handleAvailabilityClose = () => {
    setAvailabilityVisible(false);
  };

  console.log(caregiverDetails, "caregiverDetails");

  return (
    <>
      {/*      {isMobile ? (*/}
      {/*        <>*/}
      {/*        <AvailabilityModal */}
      {/*        availabilityVisible={availabilityVisible}*/}
      {/*        handleAvailabilityClose={handleAvailabilityClose}*/}
      {/*        // caregiverDetails={paymentDetails}*/}
      {/*        />*/}
      {/*          <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
      {/*            <div className="p-3 pt-4 d-flex w-100">*/}
      {/*              <Responsiveheader   title="My Caregiver" />*/}
      {/*            </div>*/}
      {/*            <div className="container-fluid">*/}
      {/*              /!* <div className="d-flex"> *!/*/}
      {/*              <div className='p-4 border-less rounded' style={{background:"#fff"}}>*/}
      {/*           <div>*/}
      {/*            <Spin */}
      {/*            spinning={loading} */}
      {/*            tip="loading...">*/}
      {/*            {caregiverDetails?.data &&*/}
      {/*            <Row>*/}

      {/*<Col   xs={24} style={{maxHeight: "100px" , display:'flex', justifyContent:'space-between'}}>*/}

      {/*                      <div>*/}
      {/*                        <div className="d-flex">*/}
      {/*                        <img */}
      {/*                        style={{borderRadius: "50px", objectFit: "cover"}}*/}
      {/*                        src={caregiverDetails?.data?.image} */}
      {/*                        width={60}*/}
      {/*                        height={60}*/}
      {/*                        />*/}
      {/*                        <div className="username d-flex" style={{marginLeft:'1rem'}}>*/}
      {/*                        <p className="fw-bold">{caregiverDetails?.data?.firstName} {caregiverDetails?.data?.lastname}</p>*/}
      {/*                        /!* {caregiverDetails?.data?.isProfileUpdated && *!/*/}
      {/*                        <img src="/images/icons/verified.svg" alt="verification" width={15} style={{position: 'relative',bottom: '14px',left: '10px'}}/>*/}
      {/*                        /!* } *!/*/}
      {/*                        </div>*/}
      {/*                        </div>*/}
      {/*                     */}
      {/*                        {caregiverDetails?.data?.ratingCount == 0 ?*/}
      {/*                          <p style={{*/}
      {/*                            left: "70px",*/}
      {/*                            bottom: "31px",*/}
      {/*                            position: "relative",*/}
      {/*                            color: '#9ea5ab'*/}
      {/*                          }}>No Ratings & Reviews</p>*/}
      {/*                        :*/}
      {/*                        <div className="d-flex" style={{    left: "70px",*/}
      {/*                          bottom: "66px",*/}
      {/*                          position: "relative"}}>*/}
      {/*                                    <img src="/images/icons/star-rating.svg" width={16} />*/}
      {/*                                    <span style={{*/}
      {/*                                        marginLeft: "8px",*/}
      {/*                                        position: "relative",*/}
      {/*                                        right: "2px",*/}
      {/*                                        fontWeight: 400,*/}
      {/*                                        fontSize: "13px"*/}
      {/*                                        }} onClick={() => {*/}
      {/*                                          if(caregiverDetails?.data?.ratingCount != 0) {*/}
      {/*                                          handleReviewModalVisible()*/}
      {/*                                          }*/}
      {/*                                        }}>{caregiverDetails?.data?.caregiverRating} ({caregiverDetails?.data?.ratingCount} reviews)</span>*/}
      {/*                        </div>*/}
      {/*                        }*/}
      {/*                      */}
      {/*                        </div>*/}

      {/*                        <div>*/}
      {/*                        <img className="bookmark-btn" src={`/images/icons/${caregiverDetails?.data?.isBookmarked ? 'bookmark-filled': 'bookmark-icon'}.svg`} width={30} height={30} onClick={handleAddDeleteBookmark} />*/}
      {/*                        <Button className="role-btn"  style={{  background: '#364BC6',*/}
      {/*            color:' #fff'}} color="primary">{caregiverDetails?.data?.userType == 3 ? 'Caregiver' : caregiverDetails?.data?.userType == '4' ? 'Companion' : ''}</Button>*/}
      {/*                    */}
      {/*                        </div>*/}
      {/*                    </Col>*/}
      {/*                   */}
      {/*                    <Col xs={24}>*/}
      {/*                    <div className="d-flex" >*/}
      {/*                                    <img src="/images/icons/location.svg" width={15} />*/}
      {/*                                    <span style={{marginLeft: "8px", fontWeight: 400,*/}
      {/*                                        fontSize: "13px",position: "relative", right: "2px"}}>*/}
      {/*                                    {isBookmarked ?*/}
      {/*                                    `${caregiverDetails?.data?.city}, ${caregiverDetails?.data?.province}`*/}
      {/*                                    :*/}
      {/*                                    `${caregiverDetails?.data?.city}, ${caregiverDetails?.data?.province}`*/}
      {/*                                    }*/}
      {/*                                    </span>*/}
      {/*                        </div>*/}
      {/*                    </Col>*/}

      {/*                <Col lg={12} xs={12}>*/}
      {/*                    <Row>*/}
      {/*                   */}
      {/*                   */}
      {/*                    </Row>*/}
      {/*                    <Row gutter={24}>*/}
      {/*                        <Col xs={24} className="pt-1" >*/}
      {/*                          <span className="profile-detail-title">Hourly Rate</span>*/}
      {/*                          <p className="profile-detail-val" style={{color:'#071F42', fontSize:'14px', fontWeight:'600'}}>${caregiverDetails?.data?.expectedRate}</p>*/}
      {/*                        </Col>*/}
      {/*                         /!* <Divider style={{ height: "65px", paddingRight:"1rem" }} type="vertical" />  *!/*/}
      {/*                        <Col xs={24} className="pt-1">*/}
      {/*                          <span className="profile-detail-title">Experience</span>*/}
      {/*                          <p className="profile-detail-val" style={{color:'#071F42', fontSize:'14px', fontWeight:'600'}}>{caregiverDetails?.data?.experience}</p>*/}
      {/*                        </Col>*/}
      {/*                        /!* <Divider style={{ height: "65px", paddingRight:"1rem" }} type="vertical" />  *!/*/}
      {/*                        <Col xs={24} >*/}
      {/*                          <span className="profile-detail-title">Qualifications</span>*/}
      {/*                          <p className="profile-detail-val" style={{color:'#071F42', fontSize:'14px', fontWeight:'600'}}>{caregiverDetails?.data?.qualification.join(",")}</p>*/}
      {/*                        </Col>*/}
      {/*                         /!* <Divider style={{ height: "65px", paddingRight:"1rem" }} type="vertical" />  *!/*/}
      {/*                        <Col xs={24} className="pt-1">*/}
      {/*                          <span className="profile-detail-title">Fully Vaccinated</span>*/}
      {/*                          <p className="profile-detail-val" style={{color:'#071F42', fontSize:'14px', fontWeight:'600'}}>{caregiverDetails?.data?.isVaccinated ? "Yes" : "No"}</p>*/}
      {/*                        </Col>*/}
      {/*                         /!* <Divider style={{ height: "65px", paddingRight:"1rem" }} type="vertical" />  *!/*/}
      {/*                        <Col xs={24}>*/}
      {/*                          <span className="profile-detail-title">Spoken English Level </span>*/}
      {/*                          <p className="profile-detail-val" style={{color:'#071F42', fontSize:'14px', fontWeight:'600'}}>{caregiverDetails?.data?.englishRate}</p>*/}
      {/*                        </Col>*/}
      {/*                        /!* <Col lg={4}>*/}
      {/*                          <span className="profile-detail-title">Relevant Qualification</span>*/}
      {/*                          <p className="profile-detail-val">Nursing</p>*/}
      {/*                        </Col> *!/*/}
      {/*                        /!* <Col lg={1}></Col> *!/*/}
      {/*                    </Row>*/}
      {/*                    <Divider type="horizontal" />*/}
      {/*                </Col>*/}
      {/*                <ScrollWrapper height={`${caregiverDetails?.data?.about?.additionComments?.length > 5 ? "calc(46vH - 200px)" : "calc(50vH - 200px)"}`} >*/}
      {/*                <Col */}
      {/*                 lg={12} xs={24}>*/}
      {/*                    <span className="profile-detail-title">Services Offered</span>*/}
      {/*                    <Row className="mt-1">*/}
      {/*                        {caregiverDetails?.data?.serviceList?.map(i => {*/}
      {/*                            return (*/}
      {/*                                <Col sm={24} className="service-offer-card d-flex mx-1 mt-2">*/}
      {/*                                    <img src={i.image} */}
      {/*                                    width={50} height={50} />*/}
      {/*                                    <span className="service-offer-text">{i?.name}</span>*/}
      {/*                                </Col>*/}
      {/*                            )*/}
      {/*                        })}*/}
      {/*                    </Row>*/}
      {/*                </Col>*/}
      {/*                <Col xl={24} lg={12} xs={12} className="mt-3">*/}
      {/*                    <span className="profile-detail-title">Animals Fine Being Around</span>*/}
      {/*                    <Row className="mt-1">*/}
      {/*                        /!* {[caregiverDetails].map(i => {*/}
      {/*                            return ( *!/*/}
      {/*                                <Col lg={2} md={6} sm={8}>*/}
      {/*                                    /!* <img src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/undefined/1668412546269_image-dfead12a-a07d-421d-9382-02a8125ecdcd-1668412546267.jpg" */}
      {/*                                    width={40} height={40} /> *!/*/}
      {/*                                    <span className="service-offer-text" style={{marginLeft: 0}}>*/}
      {/*                                      <strong>*/}
      {/*                                        {caregiverDetails?.data?.animals}*/}
      {/*                                      </strong>*/}
      {/*                                    </span>*/}
      {/*                                </Col>*/}
      {/*                            /!* )*/}
      {/*                        })} *!/*/}
      {/*                    </Row>*/}
      {/*                </Col>*/}
      {/*                <Col  xs={24} className="mt-3">*/}
      {/*                    <span className="profile-detail-title">Other Spoken Language</span>*/}
      {/*                    <Row className="mt-1 mb-3">*/}
      {/*                        {caregiverDetails?.data?.languages.map((i, idx) => {*/}
      {/*                            return (*/}
      {/*                                // <Col lg={2} md={2} sm={2}>*/}
      {/*                                    <span className="service-offer-text service-offer-languages">*/}
      {/*                                      <strong>*/}
      {/*                                        {`${i}${idx + 1 != caregiverDetails?.data?.languages?.length ? ",  " : ''}`}*/}
      {/*                                      </strong>*/}
      {/*                                    </span>*/}
      {/*                                // </Col>*/}
      {/*                            )*/}
      {/*                        })}*/}
      {/*                    </Row>*/}
      {/*                </Col>*/}
      {/*                <Col xl={24} lg={12} xs={24}>*/}
      {/*                    <span className="profile-detail-title">Do they have a car that they are willing to use</span>*/}
      {/*                </Col>*/}
      {/*                <Col xl={24} lg={12} xs={24}>*/}
      {/*                  <span className="mx-1"><strong>{caregiverDetails?.data?.haveCar ? "Yes" : "No"}</strong></span>*/}
      {/*                </Col>*/}
      {/*                <Col xl={24} lg={12} xs={24} className="mt-3">*/}
      {/*                    <span className="profile-detail-title">Additional Comments </span>*/}
      {/*                </Col>*/}
      {/*                <Col xl={24} lg={12} xs={24}>*/}
      {/*                  <span className="mx-1"><strong>{caregiverDetails?.data?.about?.additionComments ?? "N/A"}</strong></span>*/}
      {/*                </Col>*/}
      {/*                /!* <Col xl={24} lg={12} xs={12} className="d-flex align-items-center mt-3">*/}
      {/*                    <span className="profile-detail-title">Number of months as a caregiver (in the last 24 months)</span>*/}
      {/*                    <span className="mx-3">12 months</span>*/}
      {/*                </Col> *!/*/}
      {/*              </ScrollWrapper>*/}
      {/*            </Row>*/}
      {/*            }*/}
      {/*            <div className='d-flex justify-content-between mt-4'>*/}
      {/*            <Button */}
      {/*            // htmlType="submit"*/}
      {/*            onClick={() => isBookmarked ? navigate("/dashboard/my-caregivers") : navigate("/client/appointment/create-appointment/caregivers")}*/}
      {/*            className="btn-back auth-action-btn mt-3 mb-3" shape="round" size="large" style={{width: "25%"}}>*/}
      {/*            Back*/}
      {/*            </Button>*/}
      {/*            /!* <div className="d-flex"> *!/*/}
      {/*            <Button */}
      {/*            // htmlType="submit"*/}
      {/*            // onClick={() => navigate("/client/appointment/create-appointment/dates")}*/}
      {/*            onClick={handleAvailabilityVisible}*/}
      {/*            className="btn-back auth-action-btn mt-3 mb-3 mx-3 Availability" shape="round" size="large" style={{width: "30%", fontSize:'15px' }}>*/}
      {/*            Availability*/}
      {/*            </Button>*/}
      {/*           */}
      {/*            <Button */}
      {/*        // htmlType="submit"*/}
      {/*        onClick={isBookmarked ? handleChooseRelative : handleBookingAppointment}*/}
      {/*        className="btn-black auth-action-btn mt-3 mb-3" shape="round" type="primary" size="large" style={{width: "30%"}}>*/}
      {/*        Book*/}
      {/*        </Button>*/}
      {/*        /!* </div> *!/*/}
      {/*            </div>*/}
      {/*              </Spin>*/}
      {/*        </div>*/}
      {/*           </div>*/}
      {/*              </div> */}
      {/*          </div>*/}
      {/*        </>*/}
      {/*      ) : (*/}
      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div className="container-fluid appointment-dashboard">
          <div className="pt-5 d-flex profile-details-wrapper client_Expenses">
            <Sidebar />
            <div className="mx-4 message-inner-row text-left-mobile w-100">
              <AppointmentNavBar />
              <div
                className="p-4 border-less rounded caregiver-details-card-c caregiver-details-inner"
                style={{ background: "#fff" }}
              >
                <div>
                  <Spin spinning={loading}>
                    {caregiverDetails?.data && (
                      <Row className="min-h-inhret careGiver-row careGiver-row-custom">
                        <Col
                          xl={24}
                          md={24}
                          lg={24}
                          xs={24}
                          className="pr-0-mob"
                        >
                          <Row>
                            <Col
                              className="max-height-auto appointment-details-top pr-0-mob"
                              xl={12}
                              lg={12}
                              md={16}
                              xs={24}
                              style={{ maxHeight: "100px" }}
                            >
                              <img
                                alt={""}
                                style={{
                                  borderRadius: "50px",
                                  objectFit: "cover",
                                }}
                                src={caregiverDetails?.data?.image}
                                width={60}
                                height={60}
                                onClick={() => setImagePopupVisible(true)}
                              />
                              <div className="username d-flex">
                                <p className="fw-bold">
                                  {caregiverDetails?.data?.firstName}{" "}
                                  {caregiverDetails?.data?.lastName}
                                </p>
                                {/* {caregiverDetails?.data?.isProfileUpdated && */}
                                <img
                                  src="/images/icons/verified.svg"
                                  alt="verification"
                                  width={15}
                                  style={{
                                    position: "relative",
                                    bottom: "6px",
                                    left: "10px",
                                  }}
                                />
                                <Button
                                  onClick={handleAddDeleteBookmark}
                                  className="role-btn role-mobile-btn d-flex d-sm-flex d-md-none d-xl-none d-lg-none"
                                  style={{
                                    background: "#364BC6",
                                    color: " #fff",
                                    alignItems: "center",
                                    marginRight: "15px",
                                    width: "98px",
                                  }}
                                >
                                  Save
                                  <img
                                    style={{ marginLeft: "13px" }}
                                    src={`/images/icons/${
                                      caregiverDetails?.data?.isBookmarked
                                        ? "bookmark-icon-field-white"
                                        : "bookmark-white-icon"
                                    }.svg`}
                                    alt=""
                                  />
                                </Button>
                                {/*<img*/}
                                {/*  alt=""*/}
                                {/*  className="bookmark-btn d-block d-sm-block d-md-none d-xl-none d-lg-none"*/}
                                {/*  src={`/images/icons/${*/}
                                {/*    caregiverDetails?.data?.isBookmarked*/}
                                {/*      ? "bookmark-filled"*/}
                                {/*      : "bookmark-icon"*/}
                                {/*  }.svg`}*/}
                                {/*  width={30}*/}
                                {/*  height={30}*/}
                                {/*  onClick={handleAddDeleteBookmark}*/}
                                {/*/>*/}
                                {/* } */}
                              </div>
                              <div
                                className="responsive-data-caregiver responsive-data-caregiver-update"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  left: "70px",
                                  bottom: "65px",
                                  position: "relative",
                                }}
                              >
                                {caregiverDetails?.data?.ratingCount == 0 ? (
                                  <p
                                    className="rating-review-details-inner mb-0"
                                    style={{ color: "#9ea5ab" }}
                                  >
                                    No Ratings & Reviews
                                  </p>
                                ) : (
                                  <div className="d-flex rating-review-details-inner">
                                    <img
                                      alt=""
                                      src="/images/icons/star-rating.svg"
                                      width={16}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "8px",
                                        position: "relative",
                                        right: "2px",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        color: "#0F172A",
                                      }}
                                      onClick={() => {
                                        if (
                                          caregiverDetails?.data?.ratingCount !=
                                          0
                                        ) {
                                          handleReviewModalVisible();
                                        }
                                      }}
                                    >
                                      {caregiverDetails?.data?.caregiverRating}
                                      &nbsp;
                                      <span style={{ fontWeight: 500 }}>
                                        ({caregiverDetails?.data?.ratingCount}{" "}
                                        reviews)
                                      </span>
                                    </span>
                                  </div>
                                )}
                                <div className="d-flex appointment-inner location-details-div pb-mob-10">
                                  <img
                                    alt=""
                                    src="/images/icons/location.svg"
                                    width={15}
                                  />
                                  <span
                                    style={{
                                      marginLeft: "8px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      position: "relative",
                                      right: "2px",
                                      color: "#0F172A",
                                    }}
                                  >
                                    {isBookmarked
                                      ? `${caregiverDetails?.data?.city}, ${caregiverDetails?.data?.province}`
                                      : `${caregiverDetails?.data?.city}, ${caregiverDetails?.data?.province}`}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col
                              xl={12}
                              lg={12}
                              md={8}
                              xs={24}
                              className="d-flex justify-content-right app-confirm pr-0-mob"
                              style={{
                                justifyContent: "right",
                                maxHeight: "100px",
                              }}
                            >
                              {/*<img*/}
                              {/*  alt=""*/}
                              {/*  className="bookmark-btn d-none d-sm-none d-md-block d-xl-block d-lg-block"*/}
                              {/*  src={`/images/icons/${*/}
                              {/*    caregiverDetails?.data?.isBookmarked*/}
                              {/*      ? "bookmark-filled"*/}
                              {/*      : "bookmark-icon"*/}
                              {/*  }.svg`}*/}
                              {/*  width={30}*/}
                              {/*  height={30}*/}
                              {/*  onClick={handleAddDeleteBookmark}*/}
                              {/*/>*/}
                              <Button
                                onClick={handleAddDeleteBookmark}
                                className="role-btn d-none d-sm-none d-md-flex d-xl-flex d-lg-flex"
                                style={{
                                  background: "#364BC6",
                                  color: " #fff",
                                  alignItems: "center",
                                  marginRight: "15px",
                                  width: "98px",
                                }}
                              >
                                Save
                                <img
                                  style={{ marginLeft: "13px" }}
                                  src={`/images/icons/${
                                    caregiverDetails?.data?.isBookmarked
                                      ? "bookmark-icon-field-white"
                                      : "bookmark-white-icon"
                                  }.svg`}
                                  alt=""
                                />
                              </Button>
                              <Button
                                className="role-btn"
                                style={{
                                  background: "#364BC6",
                                  color: " #fff",
                                }}
                              >
                                {caregiverDetails?.data?.userType == 3
                                  ? "Caregiver"
                                  : caregiverDetails?.data?.userType == "4"
                                  ? "Companion"
                                  : ""}
                              </Button>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col lg={3} xl={2} md={12} xs={12}>
                              <span className="profile-detail-title">
                                Hourly Rate
                              </span>
                              <p className="profile-detail-val">
                                ${caregiverDetails?.data?.expectedRate}
                              </p>
                            </Col>
                            <Divider
                              style={{ height: "65px", paddingRight: "1rem" }}
                              type="vertical"
                            />
                            <Col xs={12} xl={2} md={12} lg={3}>
                              <span className="profile-detail-title">
                                Experience
                              </span>
                              <p className="profile-detail-val">
                                {caregiverDetails?.data?.experience}
                              </p>
                            </Col>
                            <Divider
                              style={{ height: "65px", paddingRight: "1rem" }}
                              type="vertical"
                            />
                            <Col xs={24} lg={12} xl={6} md={12}>
                              <span className="profile-detail-title">
                                Qualifications
                              </span>
                              <p className="profile-detail-val">
                                {caregiverDetails?.data?.qualification.join(
                                  ","
                                )}
                              </p>
                            </Col>
                            <Divider
                              style={{ height: "65px", paddingRight: "1rem" }}
                              type="vertical"
                            />
                            {/* <Col xs={12} lg={3} xl={3} md={4}>
                              <span className="profile-detail-title">
                                Fully Vaccinated
                              </span>
                              <p className="profile-detail-val">
                                {caregiverDetails?.data?.isVaccinated
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </Col> */}
                            {/* <Divider
                              style={{ height: "65px", paddingRight: "1rem" }}
                              type="vertical"
                            /> */}
                            <Col xs={12} lg={5} xl={4} md={12}>
                              <span className="profile-detail-title">
                                Spoken English Level{" "}
                              </span>
                              <p className="profile-detail-val">
                                {caregiverDetails?.data?.englishRate}
                              </p>
                            </Col>
                            {/* <Col lg={4}>
                          <span className="profile-detail-title">Relevant Qualification</span>
                          <p className="profile-detail-val">Nursing</p>
                        </Col> */}
                            {/* <Col lg={1}></Col> */}
                          </Row>
                          <Divider type="horizontal" />
                        </Col>
                        <ScrollWrapper
                          height={`${
                            caregiverDetails?.data?.about?.additionComments
                              ?.length > 5
                              ? "calc(46vH - 60px)"
                              : "calc(50vH - 200px)"
                          }`}
                          style={{ overflow: "auto" }}
                        >
                          {window.location.href.includes(
                            "create-appointment"
                          ) &&
                            unmatchedServices?.length > 0 && (
                              <Col xl={24} lg={24} xs={24} className="mb-3">
                                <span className="profile-detail-title">
                                  Services Not Offered
                                </span>
                                <Row className="mt-1 mt-mob-0 service-appointment-row">
                                  {unmatchedServices.map((i) => {
                                    return (
                                      <Col
                                        xs={12}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        className="service-appointment-col service-appointment-col-car d-flex mx-1 m-mob-0 mt-2"
                                      >
                                        <div className="w-100 d-flex service-offer-card red-border">
                                          <img
                                            alt={""}
                                            src={i.image}
                                            width={50}
                                            height={50}
                                          />
                                          <span className="service-offer-text">
                                            {i?.name}
                                          </span>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            )}
                          <Col xl={24} lg={24} xs={24}>
                            <span className="profile-detail-title">
                              Services Offered
                            </span>
                            <Row className="mt-1 mt-mob-0 service-appointment-row">
                              {caregiverDetails?.data?.serviceList?.map((i) => {
                                return (
                                  <Col
                                    xs={12}
                                    lg={8}
                                    xl={6}
                                    md={12}
                                    sm={12}
                                    className="service-appointment-col service-appointment-col-car d-flex mx-1 m-mob-0 mt-2"
                                  >
                                    <div className="w-100 d-flex service-offer-card">
                                      <img
                                        alt={""}
                                        src={i.image}
                                        width={50}
                                        height={50}
                                      />
                                      <span className="service-offer-text">
                                        {i?.name}
                                      </span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                          <Col xl={24} lg={24} xs={24} className="mt-3">
                            <span className="profile-detail-title">
                              Acceptable Pets
                            </span>
                            <Row className="mt-1">
                              {/* {[caregiverDetails].map(i => {
                            return ( */}
                              <Col lg={2} md={6} sm={8}>
                                {/* <img src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/undefined/1668412546269_image-dfead12a-a07d-421d-9382-02a8125ecdcd-1668412546267.jpg"
                                    width={40} height={40} /> */}
                                <span
                                  className="service-offer-text"
                                  style={{ marginLeft: 0 }}
                                >
                                  <strong>
                                    {caregiverDetails?.data?.animals}
                                  </strong>
                                </span>
                              </Col>
                              {/* )
                        })} */}
                            </Row>
                          </Col>
                          <Col xl={24} lg={12} xs={24} className="mt-3">
                            <span className="profile-detail-title">
                              Other Spoken Language(s)
                            </span>
                            <Row className="mt-1 mb-3">
                              {caregiverDetails?.data?.languages.map(
                                (i, idx) => {
                                  return (
                                    // <Col lg={2} md={2} sm={2}>
                                    <span className="service-offer-text service-offer-languages">
                                      <strong>
                                        {`${i}${
                                          idx + 1 !=
                                          caregiverDetails?.data?.languages
                                            ?.length
                                            ? ", "
                                            : ""
                                        }`}
                                      </strong>
                                    </span>
                                    // </Col>
                                  );
                                }
                              )}
                            </Row>
                          </Col>
                          <Col xl={24} lg={12} xs={24}>
                            <span className="profile-detail-title">
                              Do they have a car that they are willing to use
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24}>
                            <span className="mx-1 caregiver-details-descriaption">
                              <strong>
                                {caregiverDetails?.data?.haveCar ? "Yes" : "No"}
                              </strong>
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24} className="mt-3">
                            <span className="profile-detail-title">
                              Caregiver's description
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24}>
                            <span className="mx-1 caregiver-details-descriaption">
                              <strong>
                                {caregiverDetails?.data?.about?.describe ??
                                  "N/A"}
                              </strong>
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24} className="mt-3">
                            <span className="profile-detail-title">
                              Experience
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24}>
                            <span className="mx-1 caregiver-details-descriaption">
                              <strong>
                                {caregiverDetails?.data?.about?.experience ??
                                  "N/A"}
                              </strong>
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24} className="mt-3">
                            <span className="profile-detail-title">
                              Additional Comments{" "}
                            </span>
                          </Col>
                          <Col xl={24} lg={12} xs={24}>
                            <span className="mx-1 caregiver-details-descriaption">
                              <strong>
                                {caregiverDetails?.data?.about
                                  ?.additionComments ?? "N/A"}
                              </strong>
                            </span>
                          </Col>
                          {/* <Col xl={24} lg={12} xs={12} className="d-flex align-items-center mt-3">
                    <span className="profile-detail-title">Number of months as a caregiver (in the last 24 months)</span>
                    <span className="mx-3">12 months</span>
                </Col> */}
                        </ScrollWrapper>
                      </Row>
                    )}
                    <div className="d-flex careGiver-details-div justify-content-between px-3 mt-4">
                      <Button
                        // htmlType="submit"
                        onClick={() =>
                          isBookmarked
                            ? navigate("/dashboard/my-caregivers")
                            : navigate(
                                "/client/appointment/create-appointment/caregivers"
                              )
                        }
                        className="btn-back auth-action-btn car-back-btn careGiver-details-btn mt-3 mb-3"
                        shape="round"
                        size="large"
                      >
                        Back
                      </Button>
                      <div className="d-flex justify-content-end caregiver-button-div">
                        <Button
                          // htmlType="submit"
                          // onClick={() => navigate("/client/appointment/create-appointment/dates")}
                          onClick={handleAvailabilityVisible}
                          className="btn-back auth-action-btn availability-btn careGiver-details-btn mt-3 mb-3 mx-3"
                          shape="round"
                          size="large"
                          style={{ width: "35%" }}
                        >
                          Availability
                        </Button>

                        <Button
                          // htmlType="submit"
                          onClick={
                            isBookmarked
                              ? handleChooseRelative
                              : handleBookingAppointment
                          }
                          className="btn-black auth-action-btn book-btn careGiver-details-btn mt-3 mb-3"
                          shape="round"
                          type="primary"
                          size="large"
                          style={{ width: "35%" }}
                        >
                          Book
                        </Button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <RatingModal
          reviewModalVisible={reviewModalVisible}
          handleRatingModalClose={handleRatingModalClose}
          caregiverDetails={paymentDetails}
          IS_CAREGIVER={true}
        />
        <AvailabilityModal
          availabilityVisible={availabilityVisible}
          handleAvailabilityClose={handleAvailabilityClose}
          // caregiverDetails={paymentDetails}
        />
        <ImageModal
          imagePopupVisible={imagePopupVisible}
          handleClosePopup={() => setImagePopupVisible(false)}
          caregiverImage={caregiverDetails?.data?.image}
        />
      </div>
      {/*)}*/}
    </>
  );
}

export const AvailabilityModal = (props) => {
  const { availabilityVisible, handleAvailabilityClose } = props;
  const params = useParams();
  const token = localStorage.getItem("token");
  const [occupiedArr, setOccupiedArr] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [currentSlots, setCurrentSlots] = useState([]);
  const [allDatesResp, setAllDatesResp] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchSlots = async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/caregiver-availability`,
        {
          startDate:
            moment().format("yyyy-MM-DDT").toString() + "00:00:00.000+0000",
          endDate:
            moment().add(90, "days").format("yyyy-MM-DDT").toString() +
            "00:00:00.000+0000",
          userId: params?.id,
        },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
          },
        }
      )
      .then((res) => {
        let dates = res.data.output.map((i) =>
          moment(i.startTime).format("DD-MM-YYYY")
        );
        setOccupiedArr(dates);
        setAllDatesResp(res.data.output);
        console.log(res, "resp");
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  useEffect(() => {
    fetchSlots();
  }, []);

  const handleBackNavigation = () => {
    setCurrentSlots([]);
    setSelectedDate(null);
    setIsDateSelected(false);
    fetchSlots();
  };
  const maxDate = moment().add(3, "months").endOf("month").toDate();
  return (
    <Modal
      className="availability-modal-container"
      centered
      width={450}
      header={null}
      footer={null}
      open={availabilityVisible}
      onOk={handleAvailabilityClose}
      onCancel={handleAvailabilityClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div className="availability-wrapper text-center">
        {isDateSelected ? (
          <>
            <div className="title-wrapper" style={{ width: "61%" }}>
              <img
                className="back-btn"
                src="/images/icons/back-navigation.svg"
                width={10}
                onClick={handleBackNavigation}
              />
              <h3 className="modal-title" style={{ marginTop: "-22px" }}>
                Timing
              </h3>
              {/* <img className="back-btn" src="/images/icons/closeIcon.svg" width={30}
                                 onClick={handleAvailabilityClose}/> */}
            </div>
            <div className="availability-timing-wrapper">
              <p className="selected-date">
                {moment(selectedDate).format("MMM DD, YYYY")}
              </p>
              <div className="slots-wrapper">
                {currentSlots?.length > 0 &&
                  currentSlots?.map((slot) => {
                    return (
                      <div className="slot-parent" key={slot?._id}>
                        <p className="slot-tag">{`${moment(slot.startTime)
                          .zone(0)
                          .format("hh:mm A")} - ${moment(slot.endTime)
                          .zone(0)
                          .format("hh:mm A")}`}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="title-wrapper">
              <h3 className="modal-title" style={{ marginLeft: "33%" }}>
                Availability
              </h3>
              {/* <img className="back-btn" src="/images/icons/closeIcon.svg" width={30}
                                 onClick={handleAvailabilityClose}/> */}
            </div>
            <Calendar
              tileDisabled={({ date, view }) => {
                const currentDate = moment(date).format("DD-MM-YYYY");
                return !occupiedArr.includes(currentDate);
              }}
              minDetail={"month"}
              minDate={moment().toDate()}
              maxDate={maxDate}
              value={selectedDate}
              onChange={(date) => {
                const foundSlotArr = allDatesResp.filter(
                  (n) =>
                    moment(n.startTime).format("DD-MM-YYYY") ===
                    moment(date).format("DD-MM-YYYY")
                );
                setCurrentSlots(foundSlotArr);
                setSelectedDate(date);
                setIsDateSelected(true);
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export const ImageModal = (props) => {
  const { imagePopupVisible, handleClosePopup, caregiverImage } = props;
  return (
    <Modal
      className="info-modal-container image-modal-container"
      centered
      // width={450}
      header={null}
      footer={null}
      open={imagePopupVisible}
      onOk={handleClosePopup}
      onCancel={handleClosePopup}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <TransformImgWrapper>
        <div style={{ cursor: "pointer" }}>
          <img src={caregiverImage} alt="image" />
        </div>
      </TransformImgWrapper>
    </Modal>
  );
};
