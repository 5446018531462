import { Button, Col, Divider, Row, Spin, Tabs } from "antd";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import React, { useEffect, useRef, useState } from "react";
import "./appointment.less";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  paymentDetails,
  selectedCC,
} from "../../../redux/appointment/appointment.action";
import { DeclineModel } from "./caregiverAppointmentDetails";
import Footer from "../../../components/Footer/Footer";
import commonService from "../../../http/commonApi";
import {
  toggleChooseRelativeModal,
  toggleRedirectAppModal,
} from "../../../redux/modals/toggleModal.actions";

export default function AppointmentsDashboard(props) {
  const [activeTab, setActiveTab] = useState("2");
  const [loading, setLoading] = useState(false);
  const [appointmentListing, setAppointmentListing] = useState([]);
  const [onGoingAppointmentList, setOngoingAppointmentList] = useState([]);
  const [onGoingAppointment, setOngoingAppointment] = useState("");
  const [appointmentID, setAppointmentID] = useState(null);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listInnerRef = useRef();
  const [bannerStatus, setbannerStatus] = React.useState({});
  const userMode = useSelector((state) => state.loginData?.userMode);
  const appointmentData = useSelector((state) => state.appointment);
  const appointmentDetails = appointmentData.paymentDetails;
  const activeType =
    activeTab == 1 ? "pending" : activeTab == 2 ? "upcoming" : "past";
  const tabsArr = [
    {
      key: "1",
      tabName: "Pending",
    },
    {
      key: "2",
      tabName: "Upcoming",
    },
    {
      key: "3",
      tabName: "Past",
    },
  ];

  const handleTabChange = (e) => {
    if (e == 2) {
      fetchOngoingAppointments();
    }
    setCurrentPage(1);
    setAppointmentListing([]);
    setActiveTab(e);
  };
  React.useEffect(() => {
    fetchOngoingAppointments();
    getBanner();
  }, []);

  const getBanner = async () => {
    const res = await commonService._getBannerStatus();
    if (res.status === 200) {
      setbannerStatus(res?.output);
    }
  };

  const fetchOngoingAppointments = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/list-ongoing-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            if (userMode == "client") {
              setOngoingAppointmentList(response.data.output?.list);
            } else if (response.data.output) {
              setOngoingAppointment(response.data.output);
            }
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const fetchAppointmentListing = (IS_ACCEPT_DECLINE = false) => {
    const activeType =
      activeTab == 1 ? "pending" : activeTab == 2 ? "upcoming" : "past";
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/list-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: activeType,
            page: currentPage,
            pageLimit: 10,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setTotalCount(response.data.output.resultCount);
            if (IS_ACCEPT_DECLINE) {
              setAppointmentListing(response.data.output.list);
            } else {
              setAppointmentListing((prev) => [
                ...prev,
                ...response.data.output.list,
              ]);
            }
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const fetchDetails = (appointmentId, type, item) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: type ?? activeType,
            appointmentId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointment/appointment-details/${appointmentId}`
            );
            // dispatch(
            //   ActionCreator.pushBreadcrumb([
            //     { title: "Home", route: "Home" },

            //     { title: "Client", route: "Client" },
            //     { title: "appointments", route: "appointments-dashboard" },
            //     {
            //       title: "appointment-details",
            //       route: `appointment-details`,
            //     },
            //   ])
            // );

            dispatch(paymentDetails(response.data.output.result));

            //           dispatch(
            //   ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.Appointments.concat({
            //      title:item.relativeName,
            //       route:""
            //   }))
            // );
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAppointmentListing();
  }, [activeTab, currentPage]);

  const handleCaregiverActions = (IS_DECLINE, appID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/approve-or-decline`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: IS_DECLINE ? "decline" : "approve",
            appointmentId: appointmentID ?? appID,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            if (IS_DECLINE) {
              setDeclineModalVisible(false);
            }
            if (currentPage == 1) {
              fetchAppointmentListing(true);
            } else {
              setCurrentPage(1);
            }
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleDeclineModalVisible = (appId) => {
    setAppointmentID(appId);
    setDeclineModalVisible(true);
  };

  const handleDeclineModalClose = () => {
    setAppointmentID(null);
    setDeclineModalVisible(false);
  };

  const onScroll = () => {
    console.log("called", "");
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log(
        scrollTop.toFixed(0),
        scrollHeight,
        clientHeight,
        "sfsfsdfds"
      );
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (appointmentListing.length <= totalCount) {
          console.log("adasd");
          setCurrentPage((prev) => prev + 1);
        }
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const handleChooseRelative = () => {
    // dispatch(toggleRedirectAppModal(true));
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...appointmentDetails, scheduleAgain: false }));
  };

  // const toggleSchedule = () => {
  //     setShowSchedule(!showSchedule);
  // };
  //
  // const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);
  // };
  //
  // useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <>
      <div style={{ background: "#F7F9FA", minHeight: "100vH" }}>
        <div className="container-fluid appointment-dashboard appointment-dashboard-custom">
          {/*{isMobile ? (*/}
          {/*    <>*/}
          {/*        <div className='pt-3 d-flex'>*/}
          {/*            <div className='w-100'>*/}
          {/*                <div className="p-1"><Responsiveheader title="Appointments"/></div>*/}
          {/*                /!* {showSchedule &&<MySchedule /> }  *!/*/}
          {/*                <Spin spinning={loading} tip="loading...">*/}
          {/*                    {*/}
          {/*                        activeTab == 2 &&*/}
          {/*                        (userMode == 'client' ?*/}
          {/*                                onGoingAppointmentList?.map(i => {*/}
          {/*                                    return <OngoingClientAppointmentCard appointmentDetails={i}*/}
          {/*                                                                         fetchDetails={fetchDetails}/>*/}
          {/*                                })*/}
          {/*                                :*/}
          {/*                                Object.keys(onGoingAppointment).length !== 0 ?*/}
          {/*                                    <OngoingAppointmentCard appointmentDetails={onGoingAppointment}*/}
          {/*                                                            fetchDetails={fetchDetails}/>*/}
          {/*                                    :*/}
          {/*                                    ""*/}
          {/*                        )*/}
          {/*                    }*/}

          {/*                    <Tabs activeKey={activeTab} onChange={handleTabChange}*/}
          {/*                          className="appointment-tab">*/}
          {/*                        {tabsArr.map(tab => {*/}
          {/*                            return (*/}
          {/*                                <Tabs.TabPane tab={tab.tabName} key={tab.key}>*/}
          {/*                                    /!*  <div className="toggle-icon" onClick={toggleSchedule}><CalendarOutlined style={{fontSize:'25px', color:"blue"}} />*/}
          {/*           </div> *!/*/}
          {/*                                    {appointmentListing.length > 0 ?*/}
          {/*                                        <div onScroll={onScroll} ref={listInnerRef}*/}
          {/*                                             style={(onGoingAppointmentList?.length > 0 || onGoingAppointment) && activeTab == 2 ? {*/}
          {/*                                                 overflowY: 'scroll',*/}
          {/*                                                 height: '70vh'*/}
          {/*                                             } : {overflowY: 'scroll', height: '70vh'}}>*/}
          {/*                                            {appointmentListing.map(card => {*/}
          {/*                                                let duration = moment.duration(moment(card?.startTime).diff(moment().utc(true)));*/}
          {/*                                                let minutes = duration.asMinutes();*/}
          {/*                                                return userMode == 'client' ? (*/}
          {/*                                                        <AppointmentCard appointmentDetails={card}*/}
          {/*                                                                         fetchDetails={fetchDetails}*/}
          {/*                                                                         activeTab={activeTab}*/}
          {/*                                                                         minutes={minutes}/>*/}
          {/*                                                    )*/}
          {/*                                                    :*/}
          {/*                                                    (*/}
          {/*                                                        <CaregiverAppointmentCard*/}
          {/*                                                            appointmentDetails={card}*/}
          {/*                                                            fetchDetails={fetchDetails}*/}
          {/*                                                            activeTab={activeTab}*/}
          {/*                                                            handleCaregiverActions={handleCaregiverActions}*/}
          {/*                                                            handleDeclineModalVisible={handleDeclineModalVisible}*/}
          {/*                                                        />*/}
          {/*                                                    )*/}
          {/*                                            })}*/}
          {/*                                        </div>*/}
          {/*                                        :*/}
          {/*                                        <div className="no-data-wrapper">*/}
          {/*                                            <img src="/images/icons/no-appointments.svg"*/}
          {/*                                                 alt="No data"/>*/}
          {/*                                            <span className="no-data-text">No Appointments</span>*/}
          {/*                                        </div>*/}
          {/*                                    }*/}
          {/*                                </Tabs.TabPane>*/}
          {/*                            )*/}
          {/*                        })}*/}
          {/*                    </Tabs>*/}
          {/*                </Spin>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </>*/}
          {/*) : (*/}
          <>
            {/* <MySchedule /> */}
            <div className="pt-5 main-fluid d-flex">
              <Sidebar />
              <div className="mx-4 w-100 main-content main-content-appoinment">
                {/* <Breadcrumbs/> */}
                <AppointmentNavBar />
                <div className="responsive-padding w-100">
                  <Spin spinning={loading} style={{ minHeight: "80vH" }}>
                    {activeTab == 2 &&
                      (userMode == "client" &&
                      onGoingAppointmentList?.length > 0 ? (
                        <div
                          className="ongoing-wrapper ongoing-wrapper-scroll-mob"
                          style={
                            onGoingAppointmentList?.length > 1
                              ? {
                                  height: "159px",
                                  overflow: "scroll",
                                }
                              : {}
                          }
                        >
                          {onGoingAppointmentList?.map((i, index) => {
                            return (
                              <OngoingClientAppointmentCard
                                appointmentDetails={i}
                                fetchDetails={fetchDetails}
                                index={index}
                              />
                            );
                          })}
                        </div>
                      ) : Object.keys(onGoingAppointment).length !== 0 ? (
                        <OngoingAppointmentCard
                          appointmentDetails={onGoingAppointment}
                          fetchDetails={fetchDetails}
                        />
                      ) : (
                        ""
                      ))}
                    {userMode !== "client" &&
                      activeTab == 1 &&
                      bannerStatus?.isRestricted && (
                        <Row
                          className="appointment-wrapper mb-3"
                          // onClick={() => fetchDetails(appointmentDetails?.id)}
                        >
                          <Col
                            lg={24}
                            // md={24}
                            xs={24}
                            className="p-3 border-less rounded"
                            style={{ background: "#64748B" }}
                          >
                            <div>
                              <h4 style={{ color: "#fff" }}>
                                {" "}
                                Your account has been suspended for a month
                                because of 8 or more cancellations. For more
                                information email
                              </h4>
                              <h4
                                style={{
                                  color: "#fff",
                                  padding: "10px",
                                }}
                              >
                                caregiversupport@mfhomecare.com
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      )}
                    <Tabs
                      activeKey={activeTab}
                      onChange={handleTabChange}
                      className="appointment-tab"
                    >
                      {tabsArr.map((tab) => {
                        return (
                          <Tabs.TabPane tab={tab.tabName} key={tab.key}>
                            {appointmentListing.length > 0 ? (
                              <div
                                className="tab-height-auto iPad-height-appoinment-db"
                                onScroll={onScroll}
                                ref={listInnerRef}
                                style={
                                  (onGoingAppointmentList?.length > 0 ||
                                    onGoingAppointment) &&
                                  activeTab == 2
                                    ? {
                                        overflowY: "scroll",
                                        height: "50vh",
                                      }
                                    : { overflowY: "scroll", height: "70vh" }
                                }
                              >
                                {appointmentListing.map((card) => {
                                  let duration = moment.duration(
                                    moment(card?.startTime).diff(
                                      moment().utc(true)
                                    )
                                  );
                                  let minutes = duration.asMinutes();
                                  return userMode == "client" ? (
                                    <AppointmentCard
                                      appointmentDetails={card}
                                      fetchDetails={fetchDetails}
                                      activeTab={activeTab}
                                      minutes={minutes}
                                    />
                                  ) : (
                                    <CaregiverAppointmentCard
                                      appointmentDetails={card}
                                      fetchDetails={fetchDetails}
                                      Ò
                                      activeTab={activeTab}
                                      handleCaregiverActions={
                                        handleCaregiverActions
                                      }
                                      handleDeclineModalVisible={
                                        handleDeclineModalVisible
                                      }
                                    />
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="no-data-wrapper">
                                <img
                                  width={120}
                                  src="/images/icons/bird-icon.svg"
                                  alt="No data"
                                />
                                <span className="no-data-text">
                                  No Appointments
                                </span>
                                {userMode == "client" && (
                                  <img
                                    className="cursor-pointer mt-3"
                                    alt="light outline plus icon"
                                    src="/images/icons/add-first.svg"
                                    onClick={handleChooseRelative}
                                    width={50}
                                  />
                                )}
                              </div>
                            )}
                          </Tabs.TabPane>
                        );
                      })}
                    </Tabs>
                  </Spin>
                </div>
                <Footer />
              </div>
            </div>
            {userMode == "client" && appointmentListing.length > 0 && (
              <Button
                onClick={handleChooseRelative}
                className="plus-icon-add-bottom plus-icon-custom"
              >
                <img
                  alt="add-new-appointment"
                  src="/images/icons/plus-icon-white.svg"
                />
              </Button>
            )}
          </>
          {/*)}*/}
        </div>
        <DeclineModel
          declineModalVisible={declineModalVisible}
          handleDeclineModalClose={handleDeclineModalClose}
          handleCaregiverActions={handleCaregiverActions}
        />
      </div>
    </>
  );
}

const AppointmentCard = (props) => {
  const { appointmentDetails, fetchDetails, activeTab, minutes } = props;
  console.log(appointmentDetails, "appointmentDetails");
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  // const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);
  // };
  //
  // useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  // }, []);
  // console.log(isMobile, "minutes")
  return (
    <>
      {/*  {isMobile ? <>*/}
      {/*      <Row*/}
      {/*          className="appointment-wrapper mb-3"*/}
      {/*          onClick={() => fetchDetails(appointmentDetails?.id)}*/}
      {/*      >*/}
      {/*          /!* 54545 *!/*/}
      {/*          <Col*/}
      {/*              lg={24}*/}
      {/*              md={24}*/}
      {/*              xs={24}*/}
      {/*              className="p-3 border-less rounded"*/}
      {/*              style={{background: "#fff"}}*/}
      {/*          >*/}
      {/*              <Col style={{justifyContent: 'space-between', display: "flex"}} className={"pt-1"}>*/}

      {/*                  <div className="d-flex profile-wrapper">*/}
      {/*                      <img*/}
      {/*                          src={!appointmentDetails?.caregiverImage ? "/images/icons/Profile.png" : appointmentDetails?.caregiverImage}*/}
      {/*                          width={40}*/}
      {/*                          height={40}*/}
      {/*                          className="br-25 caregiver-img"*/}
      {/*                      />*/}
      {/*                      <div>*/}
      {/*                          <p className="caregiver-name">*/}
      {/*                              {appointmentDetails?.caregiverName}*/}
      {/*                          </p>*/}
      {/*                          <div className="d-flex justify-content-between flex-wrap relative-wrapper">*/}
      {/*                              <span className="book-btn">Booked for</span>*/}

      {/*                          </div>*/}
      {/*                      </div>*/}
      {/*                  </div>*/}

      {/*                  {*/}
      {/*                      activeTab == 3 ? (*/}
      {/*                          <>*/}
      {/*                              /!* <Button className="caregiver-btn" style={{width:'90px'}}>Caregiver</Button> *!/*/}
      {/*                              {appointmentDetails?.statusType == 6 ? (*/}
      {/*                                  <></>*/}
      {/*                                  // <Button className="completed"  style={{width:'100px'}}>Completed</Button>*/}
      {/*                              ) : appointmentDetails?.statusType == 4 ? (*/}
      {/*                                  <></>*/}
      {/*                                  // <Button className="cancelled"  style={{width:'100px'}}>Cancelled</Button>*/}
      {/*                              ) : appointmentDetails?.statusType == 3 ? (*/}
      {/*                                  <></>*/}
      {/*                                  // <Button className="declined"  style={{width:'100px'}}>Declined</Button>*/}
      {/*                              ) : (*/}
      {/*                                  ""*/}
      {/*                              )}*/}
      {/*                          </>*/}
      {/*                      ) : (*/}
      {/*                          <>*/}
      {/*                              <Button className="caregiver-btn">Caregiver</Button>*/}
      {/*                              {(activeTab == 2 && minutes < 10) &&*/}
      {/*                              <Button className="track-btn mx-2">Track</Button>}*/}
      {/*                          </>*/}
      {/*                      )*/}
      {/*                  }*/}
      {/*                  {*/}
      {/*                      activeTab == 3 ? (*/}
      {/*                          <>*/}
      {/*                              /!* <Button className="caregiver-btn" style={{width:'100px'}}>Caregiver</Button> *!/*/}
      {/*                              {appointmentDetails?.statusType == 6 ? (*/}
      {/*                                  <Button className="completed" style={{width: '95px'}}>Completed</Button>*/}
      {/*                              ) : appointmentDetails?.statusType == 4 ? (*/}
      {/*                                  <Button className="cancelled" style={{width: '95px'}}>Cancelled</Button>*/}
      {/*                              ) : appointmentDetails?.statusType == 3 ? (*/}
      {/*                                  <Button className="declined" style={{width: '95px'}}>Declined</Button>*/}
      {/*                              ) : (*/}
      {/*                                  ""*/}
      {/*                              )}*/}
      {/*                          </>*/}
      {/*                      ) : (*/}
      {/*                          <>*/}
      {/*                              /!* <Button className="caregiver-btn">Caregiver</Button> *!/*/}
      {/*                              /!* {(activeTab == 2 && minutes < 10) && <Button className="track-btn mx-2">Track</Button>}  *!/*/}
      {/*                          </>*/}
      {/*                      )*/}
      {/*                  }*/}
      {/*              </Col>*/}

      {/*              <Row className={"pt-4"}>*/}

      {/*                  <Col lg={12} md={12} xs={12}>*/}

      {/*                      <div className="d-flex profile-wrapper">*/}

      {/*                          <img*/}
      {/*                              src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"*/}
      {/*                              width={40}*/}
      {/*                              className="br-25 mx-2"*/}
      {/*                          />*/}

      {/*                          <p className="relative-name" style={{fontSize: '12px'}}>*/}
      {/*                              {appointmentDetails?.relativeName}*/}
      {/*                          </p>*/}

      {/*                      </div>*/}

      {/*                  </Col>*/}

      {/*                  /!* <Col lg={12} md={12} xs={12} style={{ textAlign: "right" }}>*/}
      {/*  {*/}
      {/*    activeTab == 3 ? (*/}
      {/*      <>*/}
      {/*        <Button className="caregiver-btn" style={{width:'100px'}}>Caregiver</Button>*/}
      {/*        {appointmentDetails?.statusType == 6 ? (*/}
      {/*          <></>*/}
      {/*          // <Button className="completed"  style={{width:'100px'}}>Completed</Button>*/}
      {/*        ) : appointmentDetails?.statusType == 4 ? (*/}
      {/*          <></>*/}
      {/*          // <Button className="cancelled"  style={{width:'100px'}}>Cancelled</Button>*/}
      {/*        ) : appointmentDetails?.statusType == 3 ? (*/}
      {/*          <></>*/}
      {/*          // <Button className="declined"  style={{width:'100px'}}>Declined</Button>*/}
      {/*        ) : (*/}
      {/*          ""*/}
      {/*        )}*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <>*/}
      {/*      <Button className="caregiver-btn">Caregiver</Button>*/}
      {/*      {(activeTab == 2 && minutes < 10) && <Button className="track-btn mx-2">Track</Button>} */}
      {/*     </>*/}
      {/*     )*/}
      {/*  }*/}
      {/*</Col> *!/*/}

      {/*              </Row>*/}
      {/*              <Row className="mt-3 servicelist-wrapper">*/}
      {/*                  <Col lg={4} md={6} xs={12} style={{paddingTop: isMobile ? "1rem" : "0rem"}}>*/}
      {/*                      <p className="card-title">Services</p>*/}
      {/*                      <div className="d-flex servicelist-icons">*/}
      {/*                          {appointmentDetails.serviceList.map((i) => {*/}
      {/*                              return (*/}
      {/*                                  <img*/}
      {/*                                      src={i.image}*/}
      {/*                                      width={40}*/}
      {/*                                      alt="service"*/}
      {/*                                      className="br-25 mx-1 mb-1"*/}
      {/*                                  />*/}
      {/*                              );*/}
      {/*                          })}*/}
      {/*                      </div>*/}
      {/*                  </Col>*/}
      {/*                  {isMobile ? "" : <Divider type="vertical" style={{height: '70px'}}/>}*/}
      {/*                  <Col lg={8} md={7} xs={24} className="detailing"*/}
      {/*                       style={{paddingTop: isMobile ? "1rem" : "0rem"}}>*/}
      {/*                      <p className="card-title">Location</p>*/}
      {/*                      <div className="d-flex align-items-center">*/}
      {/*                          <img src="/images/icons/location.svg" style={{height: '18px'}}/>*/}
      {/*                          /!* <p className="card-value">350 Bay St, 7th floor, Toronto, ON M5H 2S6</p> *!/*/}
      {/*                          <p className="card-value" style={{fontSize: '14px'}}>*/}
      {/*                              {`${appointmentDetails?.relativeAddressAddress?.city},*/}
      {/*                           ${appointmentDetails?.relativeAddressAddress?.province}`}*/}
      {/*                          </p>*/}
      {/*                      </div>*/}
      {/*                  </Col>*/}
      {/*                  {isMobile ? "" : <Divider type="vertical" style={{height: '70px'}}/>}*/}
      {/*                  <Col lg={2} md={5} xs={22} className="" style={{paddingTop: isMobile ? "1rem" : "0rem"}}>*/}
      {/*                      <p className="card-title">Date</p>*/}
      {/*                      <div className="d-flex align-items-center">*/}
      {/*                          <img src="/images/icons/calendar.svg" style={{height: '18px'}}/>*/}
      {/*                          <p className="card-value" style={{fontSize: '14px'}}>*/}
      {/*                              {appointmentDetails?.timing.slice(0, 5).map((n, index) => {*/}
      {/*                                  if (index != 4) {*/}
      {/*                                      return `${moment(n.date).format("MMM DD")}${*/}
      {/*                                          appointmentDetails?.timing?.length != index + 1 &&*/}
      {/*                                          index != 3*/}
      {/*                                              ? ", "*/}
      {/*                                              : ""*/}
      {/*                                      }`;*/}
      {/*                                  }*/}
      {/*                                  if (index == 4) {*/}
      {/*                                      return (*/}
      {/*                                          <span className="mx-1">*/}
      {/*              <strong>See More...</strong>*/}
      {/*            </span>*/}
      {/*                                      );*/}
      {/*                                  }*/}

      {/*                              })}*/}
      {/*                              /!* Jul 11, Jul 12, Jul 14 & Jul 15  See More... *!/*/}
      {/*                          </p>*/}
      {/*                      </div>*/}
      {/*                  </Col>*/}
      {/*                  {isMobile ? "" : <Divider type="vertical" style={{height: '70px'}}/>}*/}
      {/*                  <Col lg={2} md={5} xs={24} style={{paddingTop: isMobile ? "1rem" : "0rem"}}>*/}
      {/*                      <p className="card-title">Hours</p>*/}
      {/*                      <div className="d-flex align-items-center">*/}
      {/*                          <img src="/images/icons/time.svg" style={{height: '18px'}}/>*/}
      {/*                          <p className="card-value" style={{fontSize: '14px'}}>*/}
      {/*                              {appointmentDetails?.totalHour} Hours*/}
      {/*                          </p>*/}
      {/*                      </div>*/}
      {/*                  </Col>*/}

      {/*                  {activeTab != 3 && (<>*/}
      {/*                          {isMobile ? "" : <Divider type="vertical" style={{height: '70px'}}/>}*/}
      {/*                          <Col lg={2} md={4} xs={24}>*/}
      {/*                              <p className="card-title">Starting Time</p>*/}
      {/*                              <div className="d-flex align-items-center">*/}
      {/*                                  <img src="/images/icons/time.svg" style={{fill: "#00B383"}}/>*/}
      {/*                                  <p style={{color: "#00B383"}} className="card-value">*/}
      {/*                                      {moment(appointmentDetails?.startTime)*/}
      {/*                                          .zone(0)*/}
      {/*                                          .format("hh:mm a")}*/}
      {/*                                  </p>*/}
      {/*                              </div>*/}
      {/*                          </Col>*/}
      {/*                      </>*/}
      {/*                  )}*/}
      {/*              </Row>*/}
      {/*          </Col>*/}
      {/*      </Row>*/}
      {/*  </> :*/}
      <Row
        className="appointment-wrapper mb-3 cursor-pointer"
        onClick={() => fetchDetails(appointmentDetails?.id)}
      >
        <Col
          lg={24}
          md={24}
          xs={24}
          className="p-3 border-less rounded responsive-mobile-card-wrapper"
          style={{ background: "#fff" }}
        >
          <Row>
            <Col lg={12} md={12} xs={24} className="client-card-top">
              <div className="d-flex profile-wrapper">
                <img
                  alt=""
                  src={
                    !appointmentDetails?.caregiverImage
                      ? "/images/icons/Profile.png"
                      : appointmentDetails?.caregiverImage
                  }
                  width={40}
                  height={40}
                  className="br-25 caregiver-img"
                />
                <div>
                  <p className="caregiver-name">
                    <span className="caregiver-name-tag">
                      {appointmentDetails?.caregiverName}
                    </span>
                    <Button
                      className="caregiver-btn caregiver-btn-mobile"
                      style={{ width: "100px" }}
                    >
                      Caregiver
                    </Button>
                  </p>
                  <div className="d-flex justify-content-between flex-wrap relative-wrapper">
                    <span className="book-btn">Booked for</span>
                    <img
                      src={appointmentDetails?.relativeImage}
                      width={20}
                      height={20}
                      className="br-25 mx-2"
                    />
                    <span className="relative-name" style={{ marginLeft: "0" }}>
                      {appointmentDetails?.relativeName}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="status-btn-div"
              lg={12}
              md={12}
              xs={24}
              style={{ textAlign: "right" }}
            >
              {activeTab == 3 ? (
                <>
                  <Button
                    className="caregiver-btn d-none-mobile"
                    style={{ width: "100px" }}
                  >
                    Caregiver
                  </Button>
                  {appointmentDetails?.statusType == 6 ? (
                    <Button className="completed" style={{ width: "100px" }}>
                      Completed
                    </Button>
                  ) : appointmentDetails?.statusType == 4 ? (
                    <Button className="cancelled" style={{ width: "100px" }}>
                      Cancelled
                    </Button>
                  ) : appointmentDetails?.statusType == 3 ? (
                    <Button className="declined" style={{ width: "100px" }}>
                      Declined
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <Button className="caregiver-btn d-none-mobile">
                    Caregiver
                  </Button>
                  {/* {activeTab == 2 && minutes < 10 && (
                    <Button className="track-btn mx-2">Track</Button>
                  )} */}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-3 servicelist-wrapper">
            <Col
              lg={4}
              xl={4}
              md={24}
              xs={24}
              className="servicelist-wrapper-custom"
            >
              <p className="card-title">Services</p>
              <div className="d-flex servicelist-icons">
                {appointmentDetails.serviceList.map((i) => {
                  return (
                    <img
                      src={i.image}
                      width={40}
                      alt="service"
                      className="br-25 mx-1 mb-1"
                    />
                  );
                })}
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "70px" }} />
            <Col
              lg={6}
              xl={8}
              md={12}
              xs={24}
              className="detailing detailing-div"
            >
              <p className="card-title d-none-mobile">Location</p>
              <div className="d-flex align-items-center">
                <img alt={""} src="/images/icons/location.svg" />
                {/* <p className="card-value">350 Bay St, 7th floor, Toronto, ON M5H 2S6</p> */}
                <p className="card-value">
                  {`${appointmentDetails?.relativeAddressAddress?.city},
                                     ${appointmentDetails?.relativeAddressAddress?.province}`}
                </p>
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "70px" }} />
            <Col
              lg={3}
              xl={2}
              md={6}
              xs={24}
              className="ms-4 ms-mob-0 detailing-div"
            >
              <p className="card-title d-none-mobile">Date</p>
              <div className="d-flex align-items-center">
                <img alt="" src="/images/icons/calendar.svg" />
                <p className="card-value">
                  {appointmentDetails?.timing.slice(0, 5).map((n, index) => {
                    if (index != 4) {
                      return `${moment(n.date).utc().format("MMM DD")}${
                        appointmentDetails?.timing?.length != index + 1 &&
                        index != 3
                          ? ", "
                          : ""
                      }`;
                    }
                    if (index == 4) {
                      return (
                        <span className="mx-1">
                          <strong>See More...</strong>
                        </span>
                      );
                    }
                  })}
                  {/* Jul 11, Jul 12, Jul 14 & Jul 15  See More... */}
                </p>
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "70px" }} />
            <Col
              className="ms-mob-0 detailing-div"
              lg={3}
              xl={2}
              md={6}
              xs={10}
              style={{ marginLeft: "2rem" }}
            >
              <p className="card-title d-none-mobile">Hours</p>
              <div className="d-flex align-items-center">
                <img alt="" src="/images/icons/time.svg" />
                <p className="card-value">
                  {appointmentDetails?.totalHour} Hours
                </p>
              </div>
            </Col>

            {activeTab != 3 && (
              <>
                <Divider
                  className="d-md-none-tab"
                  type="vertical"
                  style={{ height: "70px" }}
                />
                <Col
                  className="starting-time-div starting-time-div-tab-p starting-time-div-tab-mobile"
                  lg={3}
                  xl={2}
                  md={24}
                  xs={14}
                >
                  <p className="card-title ">Starting Time</p>
                  <div className="d-flex align-items-center">
                    <img
                      className="d-none-mobile"
                      alt=""
                      src="/images/icons/time.svg"
                      style={{ fill: "#00B383" }}
                    />
                    <p style={{ color: "#00B383" }} className="card-value">
                      {moment(appointmentDetails?.startTime)
                        .zone(0)
                        .format("hh:mm a")}
                    </p>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      {/*}*/}
    </>
  );
};

const CaregiverAppointmentCard = (props) => {
  const [calcAge, setCalcAge] = useState(0);
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const {
    appointmentDetails,
    fetchDetails,
    activeTab,
    handleCaregiverActions,
    handleDeclineModalVisible,
  } = props;
  useEffect(() => {
    if (appointmentDetails?.relativeDob) {
      const currentDate = moment();
      const dob = moment(appointmentDetails?.relativeDob, "DD/MM/YYYY");
      const years = currentDate.diff(dob, "years", true);
      setCalcAge(years.toFixed(0));
    }
  }, [appointmentDetails?.relativeDob]);

  // const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);
  // };
  //
  // useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  // }, []);
  console.log(appointmentDetails, "appointmentDetails");
  return (
    <Row
      className="appointment-wrapper mb-3 cursor-pointer"
      onClick={() =>
        fetchDetails(appointmentDetails?.id, null, appointmentDetails)
      }
    >
      <Col
        lg={24}
        md={24}
        xs={24}
        className="p-3 border-less rounded responsive-mobile-card-wrapper"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col lg={12} md={12} xs={24} className="mob-mb-3">
            <div className="d-flex profile-wrapper">
              <img
                alt=""
                src={
                  !appointmentDetails?.relativeImage
                    ? "/images/icons/Profile.png"
                    : appointmentDetails?.relativeImage
                }
                width={40}
                height={40}
                className="br-25 caregiver-img"
              />
              <div>
                <p className="caregiver-name">
                  <span>{appointmentDetails?.relativeName} </span>
                  <span className="color-secondary ml-mob-5">{` • ${calcAge} Years`}</span>
                </p>
                <div className="d-flex justify-content-between flex-wrap relative-wrapper">
                  <span className="book-btn">Booked by</span>
                  {/* <img src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"
                                        width={20} className='br-25 mx-2' /> */}
                  <span className="relative-name mx-2 ml-mob-5 mr-mob-0">
                    {appointmentDetails?.clientName}
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="starting-time-div starting-time-div-ipad starting-time-div-caregiver"
            lg={12}
            md={12}
            xs={24}
            style={{ textAlign: "right" }}
          >
            {activeTab != 3 ? (
              <p>
                <strong>Starting Time:</strong>{" "}
                {moment(appointmentDetails?.startTime)
                  .zone(0)
                  .format("hh:mm a")}
              </p>
            ) : appointmentDetails?.statusType == 6 ? (
              <Button className="completed">Completed</Button>
            ) : appointmentDetails?.statusType == 4 ? (
              <Button className="cancelled">Cancelled</Button>
            ) : appointmentDetails?.statusType == 3 ? (
              <Button className="declined">Declined</Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="mt-3 servicelist-wrapper">
          <Col className="detailing-div" lg={4} xl={4} md={24} xs={24}>
            <p className="card-title">Services</p>
            <div className="d-flex servicelist-icons">
              {appointmentDetails.serviceList.map((i) => {
                return (
                  <img
                    src={i.image}
                    width={40}
                    alt="service"
                    className="br-25 mx-1 mb-1"
                  />
                );
              })}
            </div>
          </Col>
          <Divider type="vertical" style={{ height: "70px" }} />
          <Col
            lg={7}
            xl={6}
            md={14}
            xs={24}
            className="Location-section detailing-div"
          >
            <p className="card-title d-none-mobile">Location</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/location.svg" />
              {/* <p className="card-value">350 Bay St, 7th floor, Toronto, ON M5H 2S6</p> */}
              <p className="card-value">
                {`${appointmentDetails?.relativeAddressAddress?.city},
                                     ${appointmentDetails?.relativeAddressAddress?.province}`}
              </p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: "70px" }} />
          <Col
            lg={activeTab == 1 ? 3 : 4}
            xl={activeTab == 1 ? 3 : 4}
            md={5}
            xs={24}
            className="detailing-div"
          >
            <p className="card-title d-none-mobile">Date</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/calendar.svg" />
              <p className="card-value">
                {appointmentDetails?.timing.slice(0, 5).map((n, index) => {
                  if (index != 4) {
                    return `${moment(n.date).utc().format("MMM DD")}${
                      appointmentDetails?.timing?.length != index + 1 &&
                      index != 3
                        ? ", "
                        : ""
                    }`;
                  }
                  if (index == 4) {
                    return (
                      <span className="mx-1">
                        <strong>See More...</strong>
                      </span>
                    );
                  }
                })}
                {/* Jul 11, Jul 12, Jul 14 & Jul 15  See More... */}
              </p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: "70px" }} />
          <Col
            lg={activeTab == 1 ? 3 : 4}
            xl={activeTab == 1 ? 3 : 4}
            md={5}
            xs={24}
            className="detailing-div"
          >
            <p className="card-title d-none-mobile">Hours</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/time.svg" />
              <p className="card-value">
                {appointmentDetails?.totalHour} Hours
              </p>
            </div>
          </Col>
          {activeTab == 1 && (
            <>
              {/*  {isMobile ? "" :  <Divider type="vertical" style={{height: '70px'}} />} */}
              <Col
                lg={24}
                md={24}
                xl={6}
                xs={24}
                className="d-flex accept-decline-btn-col"
                style={{ justifyContent: "end" }}
              >
                <Button
                  // htmlType="submit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeclineModalVisible(appointmentDetails?.id);
                    // handleCaregiverActions(true, appointmentDetails?.id)
                  }}
                  className="ant-btn-round ant-btn-default btn-back auth-action-btn mt-3 mb-3 text-center"
                  shape="round"
                  size="small"
                  style={{ width: "5%" }}
                >
                  Decline
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCaregiverActions(false, appointmentDetails?.id);
                  }}
                  className="btn-black auth-action-btn mt-3 mb-3 mx-3"
                  shape="round"
                  type="primary"
                  size="small"
                  style={{ width: "5%" }}
                >
                  Accept
                </Button>
                {/* <p className="card-title">Starting Time</p>
                            <div className="d-flex align-items-center">
                                <img src="/images/icons/time.svg" style={{fill: '#00B383'}} />
                                <p style={{color: '#00B383'}} className="card-value">{moment(appointmentDetails?.startTime).zone(0).format('hh:mm a')}</p>
                            </div> */}
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const OngoingAppointmentCard = (props) => {
  const { appointmentDetails, fetchDetails, activeTab } = props;
  const [age, setCalcAge] = useState(0);
  useEffect(() => {
    if (appointmentDetails?.relativeDob) {
      const currentDate = moment();
      const dob = moment(appointmentDetails?.relativeDob, "DD/MM/YYYY");
      const years = currentDate.diff(dob, "years", false);
      setCalcAge(years);
    }
  }, [appointmentDetails?.relativeDob]);
  return (
    <Row
      className="appointment-wrapper mb-3 cursor-pointer"
      onClick={() => fetchDetails(appointmentDetails?._id, "ongoing")}
    >
      <Col
        lg={24}
        md={24}
        xs={24}
        className="p-3 border-less rounded responsive-mobile-card-wrapper"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col lg={12} md={12} xs={18}>
            <div className="d-flex profile-wrapper">
              <img
                alt=""
                src={
                  !appointmentDetails?.relativeImage
                    ? "/images/icons/Profile.png"
                    : appointmentDetails?.relativeImage
                }
                width={40}
                height={40}
                className="br-25 caregiver-img"
              />
              <div>
                <p className="caregiver-name">
                  {appointmentDetails?.relativeName}
                  <span className="color-secondary ml-mob-5">{` • ${age} Years`}</span>
                </p>
                <div className="d-flex justify-content-between flex-wrap-nowrap-mob flex-wrap relative-wrapper">
                  <span className="book-btn">Booked by</span>
                  {/* <img alt=""
                                        src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"
                                        width={20}
                                        className="br-25 mx-2"
                                    /> */}
                  <span className="relative-name">
                    {appointmentDetails?.clientName}
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} md={12} xs={6} style={{ textAlign: "right" }}>
            {appointmentDetails?.isExtendedAppointmentRequest ? (
              <span
                className="mb-0"
                style={{
                  color: "#00B383",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    width: "5px",
                    height: "5px",
                    background: "#00B383",
                    borderRadius: "50%",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                ></span>
                Extension Request
              </span>
            ) : (
              <Button className="ongoing">Ongoing</Button>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          {/* <Col lg={3} md={3} xs={24}>
                    <p className="card-title">Services</p>
                    <div className='d-flex justify-content-between'>
                    {appointmentDetails.serviceList.map(i => {
                        return (
                            <img src={i.image} 
                            width={30} alt='service' 
                            className='br-25'
                            />
                        )
                    })}
                    </div>
                </Col> */}
          <Col lg={7} md={7} xs={24}>
            <p className="card-title">Location</p>
            <div className="d-flex align-items-center">
              <img align="" alt="" src="/images/icons/location.svg" />
              {/* <p className="card-value">350 Bay St, 7th floor, Toronto, ON M5H 2S6</p> */}
              <p className="card-value">
                {`${appointmentDetails?.relativeAddressAddress?.city},
                             ${appointmentDetails?.relativeAddressAddress?.province}`}
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} xs={24}>
            <p className="card-title d-none-mobile">Date</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/calendar.svg" />
              <p className="card-value">
                {moment(appointmentDetails?.date).utc().format("MMM DD")}
              </p>
            </div>
          </Col>
          {/* <Col lg={3} md={3} xs={24}>
                    <p className="card-title">Hours</p>
                    <div className="d-flex align-items-center">
                        <img src="/images/icons/time.svg" />
                        <p className="card-value">{appointmentDetails?.totalHour} Hours</p>
                    </div>
                </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

const OngoingClientAppointmentCard = (props) => {
  const { appointmentDetails, fetchDetails, activeTab, index } = props;
  return (
    <Row
      className="appointment-wrapper mb-3 cursor-pointer"
      onClick={() => fetchDetails(appointmentDetails?._id, "ongoing")}
    >
      <Col
        lg={24}
        md={24}
        xs={24}
        className="p-3 border-less rounded responsive-mobile-card-wrapper"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col lg={12} md={12} xs={18}>
            <div className="d-flex profile-wrapper">
              <img
                alt=""
                src={
                  !appointmentDetails?.caregiverImage
                    ? "/images/icons/Profile.png"
                    : appointmentDetails?.caregiverImage
                }
                width={40}
                height={40}
                className="br-25 caregiver-img"
              />
              <div className="w-100-mob">
                <p className="caregiver-name">
                  {appointmentDetails?.caregiverName}
                </p>
                <div className="d-flex justify-content-between flex-wrap relative-wrapper relative-wrapper-ongoing">
                  <span className="book-btn">Booked for</span>
                  <img
                    src={appointmentDetails?.relativeImage}
                    width={20}
                    height={20}
                    className="br-25 mx-2"
                  />
                  {/* <img
                    src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"
                    width={20}
                    className="br-25 mx-2"
                  /> */}
                  <span
                    className="relative-name relative-name-ongoing"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {appointmentDetails?.relativeName}
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="status-btn-div"
            lg={12}
            md={12}
            xs={6}
            style={{ textAlign: "right" }}
          >
            {appointmentDetails?.isExtendedAppointmentRequest && (
              <Button className="extension-pending">Extension Pending</Button>
            )}
            <Button className="ongoing">Ongoing</Button>
            {/* <Button className="track-btn mx-2">Track</Button> */}
          </Col>
        </Row>
        <Row className="mt-3">
          {/* <Col lg={3} md={3} xs={24}>
                    <p className="card-title">Services</p>
                    <div className='d-flex justify-content-between'>
                    {appointmentDetails.serviceList.map(i => {
                        return (
                            <img src={i.image} 
                            width={30} alt='service' 
                            className='br-25'
                            />
                        )
                    })}
                    </div>
                </Col> */}
          <Col className="detailing-div" lg={7} md={12} xs={24}>
            <p className="card-title">Location</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/location.svg" />
              {/* <p className="card-value">350 Bay St, 7th floor, Toronto, ON M5H 2S6</p> */}
              <p className="card-value">
                {`${appointmentDetails?.caregiverAddress?.city},
                             ${appointmentDetails?.caregiverAddress?.province}`}
              </p>
            </div>
          </Col>
          <Col className="detailing-div" lg={6} md={6} xs={24}>
            <p className="card-title d-none-mobile">Date</p>
            <div className="d-flex align-items-center">
              <img alt="" src="/images/icons/calendar.svg" />
              <p className="card-value">
                {moment(appointmentDetails?.date).utc().format("MMM DD")}
              </p>
            </div>
          </Col>
          {/* <Col lg={3} md={3} xs={24}>
                    <p className="card-title">Hours</p>
                    <div className="d-flex align-items-center">
                        <img src="/images/icons/time.svg" />
                        <p className="card-value">{appointmentDetails?.totalHour} Hours</p>
                    </div>
                </Col> */}
        </Row>
      </Col>
    </Row>
  );
};
