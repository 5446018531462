import { Button, Col, Divider, Row, Spin } from "antd";
import Sidebar from "../../../../../components/sidebar";
import AppointmentNavBar from "../../../../client/appointment/appointmentsNavBar";
import "../../../../client/appointment/service-grid.less";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
// import { RatingModal } from "./careGiverDetails";
import axios from "axios";
import { setAlert } from "../../../../../redux/alert/alert.action";
import ScrollWrapper from "../../../../../components/ScrollWrapper/ScrollWrapper";
import ActionCreator from "../../../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../../../components/Footer/Footer";
import Responsiveheader from "../../../../../components/Responsiveheader";
import BookingCompletedModal from "../../../../client/appointment/BookingCompletedModal";
import { ImageModal } from "../../../../client/appointment/careGiverDetails";

export default function ExtendBookingConfirmation(props) {
  const [loading, setLoading] = useState(false);
  const [bookingCompletionVisible, setBookingVisible] = useState(false);
  const [caregiverDetails, setCaregiverDetails] = useState(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentData = useSelector((state) => state.appointment);
  const { extendBooking } = appointmentData.extendBooking;
  console.log(appointmentData, "extendBooking");
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const params = useLocation();
  console.log(params, "fsdfsfs");
  const selectedCCId = useSelector((state) =>
    state.appointment.selectedCC?.caregiverId
      ? state.appointment.selectedCC?.caregiverId
      : state.appointment.selectedCC?._id
  );
  const selectedCC = useSelector((state) => state.appointment.selectedCC);
  const { paymentDetails } = appointmentData;

  // extendBooking
  useEffect(() => {
    // fetchGiverDetails();
    if (params.state?.isBookmarked) {
      const breadCrumbArr = [...ActionCreator.BreadcrumbItems.caregivers];
      breadCrumbArr.push({
        title: `${selectedCC?.firstName} ${selectedCC?.lastName}`,
        route: `my-caregivers/view-caregiver/${selectedCCId}`,
        isCustom: true,
      });
      breadCrumbArr.push({
        title: "New Appointment",
        route: "",
      });
      dispatch(ActionCreator.pushBreadcrumb(breadCrumbArr));
    } else {
      dispatch(
        ActionCreator.pushBreadcrumb(
          ActionCreator.BreadcrumbItems.Appointments.concat({
            title: "New Appointment",
            route: "",
          })
        )
      );
    }
    setBookingVisible(true);
  }, []);

  const fetchGiverDetails = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/view-caregiver`,
          {
            selectedServices: [
              ...appointmentData?.servicesResp.checkedCaregiver,
              ...appointmentData?.servicesResp.checkedCompanions,
            ],
            caregiverId: extendBooking?.caregiverId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setCaregiverDetails(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleReviewModalVisible = () => {
    setReviewModalVisible(true);
  };

  const handleRatingModalClose = () => {
    setReviewModalVisible(false);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{isMobile?<>*/}

      {/*   <div className="p-3 pt-4 d-flex w-100">*/}
      {/*           <Responsiveheader  title="Booking Confirmation" />*/}
      {/*         </div>*/}
      {/*         <div className='border-less rounded mx-2' style={{ background: "#fff", padding: '15px 25px' }}>*/}
      {/*                           <div className="Pending-appointment-content">*/}
      {/*                               <div className="">*/}
      {/*                                   <p className='mt-2 fw-semibold fs-4 mb-3' style={{whiteSpace:'pre'}}>Booking Confirmation</p>*/}
      {/*                                   <p className="mt-2 mb-3 profile-detail-title" style={{fontSize: '16px',}}>{moment(extendBooking?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p>*/}
      {/*                               </div>*/}
      {/*                               <ScrollWrapper>*/}
      {/*                               <Spin*/}
      {/*                                   spinning={loading}*/}
      {/*                                   tip="loading...">*/}
      {/*                                   <div className="payment-wrapper" style={{ width: "100%" }}>*/}
      {/*                                       <Row>*/}
      {/*                                           <Col xl={24} lg={24}>*/}
      {/*                                               <Row>*/}
      {/*                                                   <Col xl={12} lg={12} md={12}>*/}
      {/*                                                       <div className="amount-wrapper booking-confirm" style={{padding:'39px'}}>*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                               <p className="price-title">Transaction ID</p>*/}
      {/*                                                               <p className="price-value">{extendBooking?.transactionId}</p>*/}
      {/*                                                           </div>*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                               <p className="price-title">Payment Type</p>*/}
      {/*                                                               <p className="price-value">{paymentDetails?.paymentMethod}</p>*/}
      {/*                                                           </div>*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                               <p className="price-title">Total Hours</p>*/}
      {/*                                                               <p className="price-value">{paymentDetails?.totalHour}</p>*/}
      {/*                                                           </div>*/}
      {/*                                                           <Divider />*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                               <p className="price-title">Caregiver Price</p>*/}
      {/*                                                               <p className="price-value">${paymentDetails?.caregiverActualPrice}</p>*/}
      {/*                                                           </div>*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                           <p className="price-title">Additional Fee & Taxes</p>*/}
      {/*                                                           <p className="price-value">*/}
      {/*                                                           {`(${paymentDetails?.additionalTaxPercentage || 0}%)`} ${paymentDetails?.additionalTaxAmount || 0}*/}
      {/*                                                           </p>*/}
      {/*                                                           </div>*/}
      {/*                                                           <div className="d-flex justify-content-between align-items-center total">*/}
      {/*                                                               <p className="price-title">Total Amount</p>*/}
      {/*                                                               <p className="price-value">${paymentDetails?.totalAmount}</p>*/}
      {/*                                                           </div>*/}
      {/*                                                       </div>*/}
      {/*                                                   </Col>*/}
      {/*                                               </Row>*/}

      {/*                                               <Row  style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>*/}
      {/*                                                           <Col  xs={12} style={{ maxHeight: "100px" }}>*/}
      {/*                                                               <div className="d-flex">*/}
      {/*                                                               <img*/}
      {/*                                                                   style={{ borderRadius: "50px" }}*/}
      {/*                                                                   src={paymentDetails?.caregiverImage}*/}
      {/*                                                                   width={60}*/}
      {/*                                                               /> */}
      {/*                                                               <div className="username d-flex" style={{marginLeft:'1rem' ,whiteSpace:'pre'}}>*/}
      {/*                                                                   <p className="fw-bold">{paymentDetails?.caregiverName}</p>*/}
      {/*                                                                   /!* {caregiverDetails?.data?.isProfileUpdated && *!/*/}
      {/*                                                                       <img src="/images/icons/verified.svg" alt="verification" width={15} style={{ position: 'relative', bottom: '17px', left: '10px' }} />*/}
      {/*                                                                   /!* } *!/*/}
      {/*                                                               </div>*/}
      {/*                                                               </div>*/}
      {/*                                                               <div className="d-flex" style={{*/}
      {/*                                                                   left: "70px",*/}
      {/*                                                                   bottom: "31px",*/}
      {/*                                                                   position: "relative"*/}
      {/*                                                               }}>*/}
      {/*                                                                   <img src="/images/icons/star-rating.svg" width={16} />*/}
      {/*                                                                   <span style={{*/}
      {/*                                                                       marginLeft: "8px",*/}
      {/*                                                                       position: "relative",*/}
      {/*                                                                       right: "2px",*/}
      {/*                                                                       fontWeight: 400,*/}
      {/*                                                                       fontSize: "13px"*/}
      {/*                                                                   }} onClick={handleReviewModalVisible}>{paymentDetails?.caregiverRating} ({paymentDetails?.ratingCount} reviews)</span>*/}
      {/*                                                               </div>*/}
      {/*                                                              */}
      {/*                                                           </Col>*/}
      {/*                                                           <Col  xs={12} className="d-flex justify-content-right" style={{ justifyContent: "right", maxHeight: "100px", }}>*/}
      {/*                                                               /!* <img className="bookmark-btn" src="/images/icons/bookmark-icon.svg" width={30} height={30} /> *!/*/}
      {/*                                                               <Button className="role-btn" style={{marginRight:"1rem" }}>{caregiverDetails?.data?.userType == 3 ? 'Caregiver' : caregiverDetails?.data?.userType == '4' ? 'Companion' : ''}</Button>*/}
      {/*                                                           </Col>*/}
      {/*                                                          */}
      {/*                                                           */}
      {/*                                                       </Row>*/}
      {/*                                               <Row style={{paddingTop: '25px'}}>*/}
      {/*                                               <Col xs={24} >*/}
      {/*                                                           <div className="d-flex location-container" >*/}
      {/*                                                                   <img src="/images/icons/location.svg" width={15} />*/}
      {/*                                                                   <span style={{*/}
      {/*                                                                       marginLeft: "8px", fontWeight: 400,*/}
      {/*                                                                       fontSize: "13px",*/}
      {/*                                                                   }}>{paymentDetails?.caregiverAddress?.city}, {paymentDetails?.caregiverAddress?.province}</span>*/}
      {/*                                                               </div>*/}
      {/*                                                           </Col>*/}
      {/*                                                   <Col xs={24}>*/}
      {/*                                                     */}
      {/*                                                       <Row gutter={24}>*/}
      {/*                                                           <Col lg={24} md={24} sm={24}  className="pt-3">*/}
      {/*                                                               <span className="profile-detail-title">Booked For</span>*/}
      {/*                                                           </Col>*/}
      {/*                                                           <Col xs={24} className="d-flex pt-3">*/}
      {/*                                                           {*/}
      {/*                                                               paymentDetails?.relativeImage ? */}
      {/*                                                               <img*/}
      {/*                                                               style={{ borderRadius: "50px" }}*/}
      {/*                                                               src={paymentDetails?.relativeImage}*/}
      {/*                                                               width={40}*/}
      {/*                                                               height={40}*/}
      {/*                                                           />*/}
      {/*                                                           :*/}
      {/*                                                            <img*/}
      {/*                                                             style={{ borderRadius: "50px" }}*/}
      {/*                                                             width={40}*/}
      {/*                                                             height={40}*/}
      {/*                                                             src='/images/icons/Profile.png'*/}
      {/*                                                           />*/}
      {/*                                                           }*/}
      {/*                                                          */}
      {/*                                                               <div className="mx-2">*/}
      {/*                                                                   <p className="fw-bold mb-1">{paymentDetails?.relativeName}</p>*/}
      {/*                                                               </div>*/}
      {/*                                                           </Col>*/}
      {/*                                                           /!* <Divider type="vertical" style={{height: '70px'}} /> *!/*/}
      {/*                                                           <Col xs={24}  className="pt-3">*/}
      {/*                                                           <div className="d-flex" >*/}
      {/*                                                                   <img src="/images/icons/location.svg" width={15} />*/}
      {/*                                                                   <p style={{*/}
      {/*                                                                        fontWeight: 400,*/}
      {/*                                                                       fontSize: '16px', */}
      {/*                                                                   }}>{paymentDetails?.relativeAddressAddress?.address}, {paymentDetails?.relativeAddressAddress?.city}, {paymentDetails?.relativeAddressAddress?.province}, {paymentDetails?.relativeAddressAddress?.postalCode}</p>*/}
      {/*                                                               </div>*/}
      {/*                                                           </Col>*/}

      {/*                                                           /!* <Divider type="vertical" style={{height: '70px'}} /> *!/*/}
      {/*                                                           <Col xs={24}  className="pt-3">*/}
      {/*                                                           <div className="d-flex" >*/}
      {/*                                                                   <img src="/images/icons/time.svg" width={20} />*/}
      {/*                                                                   <span style={{fontWeight: 400,*/}
      {/*                                                                       fontSize: '16px', lineHeight: '22px',marginLeft:'11px'}}>{paymentDetails?.totalHour} Hours</span>*/}
      {/*                                                               </div>*/}
      {/*                                                           </Col>*/}

      {/*                                                           /!* <Divider type="vertical" style={{height: '70px'}} /> *!/*/}
      {/*                                                           <Col xs={24}  className="pt-3">*/}
      {/*                                                               <span className="profile-detail-title">Hourly Rate</span>*/}
      {/*                                                               <p className="profile-detail-val">${paymentDetails?.caregiverActualPrice}</p>*/}
      {/*                                                           </Col>*/}
      {/*                                                       </Row>*/}
      {/*                                                       <Divider type="horizontal" />*/}
      {/*                                                   </Col>*/}
      {/*                                                   <Col  xs={24} className="mb-3">*/}
      {/*                                                       <span className="profile-detail-title">Services</span>*/}
      {/*                                                       <Row className="mt-3">*/}
      {/*                                                           {paymentDetails?.serviceList.map(i => {*/}
      {/*                                                               return (*/}
      {/*                                                                   <Col lg={6} md={8} sm={12} className="service-offer-card d-flex mx-1 mt-2">*/}
      {/*                                                                       <img src={i.image}*/}
      {/*                                                                           width={40} height={40} />*/}
      {/*                                                                       <span className="service-offer-text">{i?.name}</span>*/}
      {/*                                                                   </Col>*/}
      {/*                                                               )*/}
      {/*                                                           })}*/}
      {/*                                                       </Row>*/}
      {/*                                                   </Col>*/}
      {/*                                                   <Col  xs={24}>*/}
      {/*                                                       <span className="profile-detail-title">Date and Time</span>*/}
      {/*                                                       <Row className="mt-3">*/}
      {/*                                                           {paymentDetails.timing.map(i => {*/}
      {/*                                                               return (*/}
      {/*                                                                   <Col lg={3} md={6} sm={8} className="service-offer-card d-flex mx-1 mt-2">*/}
      {/*                                                                       <div className="date-card">{moment(i.date).format("MMM")} <span style={{fontWeight: 700, marginTop: '-5px'}}>{moment(i.date).format("DD")}</span></div>*/}
      {/*                                                                       <span className="time-text">{moment(i.startTime).zone(0).format("hh:mm a")} to {moment(i.endTime).zone(0).format("hh:mm a")}</span>*/}
      {/*                                                                       /!* <span className="service-offer-text">{i?.name}</span> *!/*/}
      {/*                                                                   </Col>*/}
      {/*                                                               )*/}
      {/*                                                           })}*/}
      {/*                                                       </Row>*/}
      {/*                                                   </Col>*/}
      {/*                                                   <Col  xs={24} className="mt-3">*/}
      {/*                                                       <span className="profile-detail-title">Additional Comments</span>*/}
      {/*                                                       <Row className="mt-3 mb-3">*/}
      {/*                                                           <span className="profile-detail-title">*/}
      {/*                                                            {paymentDetails?.additionalNote?.additionComments}*/}
      {/*                                                           </span>*/}
      {/*                                                       </Row>*/}
      {/*                                                   </Col>*/}
      {/*                                               </Row>*/}
      {/*                                           </Col>*/}
      {/*                                       </Row>*/}

      {/*                                   </div>*/}
      {/*                                  */}
      {/*                               </Spin>*/}
      {/*                               </ScrollWrapper>*/}
      {/*                               <div className='d-flex justify-content-right text-right px-3 mt-4' style={{justifyContent:"end"}}>*/}
      {/*                               /!* <Button*/}
      {/*                                   // htmlType="submit"*/}
      {/*                                   onClick={() => navigate("/client/appointment/create-appointment/caregivers")}*/}
      {/*                                   className="btn-back auth-action-btn mt-3 mb-3" shape="round" size="large">*/}
      {/*                                   Back*/}
      {/*                               </Button> *!/*/}
      {/*                               <Button*/}
      {/*                                   // htmlType="submit"*/}
      {/*                                   onClick={() => navigate("/client/appointments-dashboard")}*/}
      {/*                                   // disabled={!selectedCard}*/}
      {/*                                   className="btn-black auth-action-btn mt-3 mb-3" shape="round" type="primary" size="large">*/}
      {/*                                   Done*/}
      {/*                               </Button>*/}
      {/*                           </div>*/}
      {/*                           </div>*/}
      {/*                       </div>*/}
      {/*</>:*/}
      <>
        <div className="container-fluid appointment-dashboard">
          <div className="pt-5 d-flex profile-details-wrapper client_Expenses">
            <Sidebar />
            <div className="mx-4 w-100 message-inner-row">
              <AppointmentNavBar />
              <div
                className="border-less rounded"
                style={{ background: "#fff", padding: "15px 25px" }}
              >
                <div className="Pending-appointment-content booking-confirmation-main w-100">
                  <div className="d-flex justify-content-between top-heading-booking-confirm">
                    <p className="mt-2 fw-semibold fs-4 mb-3">
                      Booking Confirmation
                    </p>
                    <p
                      className="mt-2 mb-3 profile-detail-title"
                      style={{ fontSize: "16px" }}
                    >
                      {moment(extendBooking?.bookedAt)
                        .utc()
                        .format("MMMM DD, YYYY HH:MM a")}
                    </p>
                  </div>
                  <ScrollWrapper height="calc(65vh - 100px)">
                    <Spin spinning={loading}>
                      <div
                        className="payment-wrapper"
                        style={{ width: "100%" }}
                      >
                        <Row>
                          <Col xl={24} lg={24} xs={24}>
                            <Row>
                              <Col xl={12} lg={12} xs={24} md={24}>
                                <div className="amount-wrapper booking-confirm">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Caregiver Hourly Rate
                                    </p>
                                    <p className="price-value">
                                      ${paymentDetails?.caregiverActualPrice}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Total Extended Hours
                                    </p>
                                    <p className="price-value">
                                      {paymentDetails?.totalHour} Hours
                                    </p>
                                  </div>
                                  <Divider />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">Total</p>
                                    <p className="price-value">
                                      ${paymentDetails?.totalAmountWithoutTax}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Taxes{" "}
                                      {`(${
                                        paymentDetails?.additionalTaxPercentage ||
                                        0
                                      }%)`}
                                    </p>
                                    <p className="price-value">
                                      $
                                      {paymentDetails?.additionalTaxAmount || 0}
                                    </p>
                                  </div>
                                  <Divider />
                                  <div className="d-flex justify-content-between align-items-center total">
                                    <p className="price-title">Total Amount</p>
                                    <p className="price-value">
                                      ${paymentDetails?.totalAmount}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ paddingTop: "25px" }}>
                              <Col xl={24} lg={12} md={24} xs={24}>
                                <Row className="booking-confirmation-row">
                                  <Col
                                    className="max-height-auto text-left-mobile"
                                    xl={12}
                                    lg={12}
                                    md={16}
                                    xs={24}
                                    style={{ maxHeight: "100px" }}
                                  >
                                    <img
                                      alt={""}
                                      style={{
                                        borderRadius: "50%",
                                        width: 60,
                                        height: 60,
                                      }}
                                      src={paymentDetails?.caregiverImage}
                                      // width={60}
                                      onClick={() => {
                                        if (paymentDetails?.caregiverImage) {
                                          setImagePopupVisible(true);
                                        }
                                      }}
                                    />
                                    <div className="username d-flex">
                                      <p className="fw-bold">
                                        {paymentDetails?.caregiverName}
                                      </p>
                                      {/* {caregiverDetails?.data?.isProfileUpdated && */}
                                      <img
                                        src="/images/icons/verified.svg"
                                        alt="verification"
                                        width={15}
                                        style={{
                                          position: "relative",
                                          bottom: "6px",
                                          left: "10px",
                                        }}
                                      />
                                      {/* } */}
                                    </div>
                                    <div
                                      className="d-flex responsive-data-caregiver review-card-mob"
                                      style={{
                                        left: "70px",
                                        bottom: "66px",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        alt={""}
                                        src="/images/icons/star-rating.svg"
                                        width={16}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          position: "relative",
                                          right: "2px",
                                          fontWeight: 400,
                                          fontSize: "13px",
                                        }}
                                        onClick={handleReviewModalVisible}
                                      >
                                        {paymentDetails?.caregiverRating} (
                                        {paymentDetails?.ratingCount} reviews)
                                      </span>
                                    </div>
                                    <div
                                      className="d-flex location-container"
                                      style={{
                                        left: "15vw",
                                        position: "relative",
                                        bottom: "85px",
                                      }}
                                    >
                                      <img
                                        alt={""}
                                        src="/images/icons/location.svg"
                                        width={15}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          fontWeight: 400,
                                          fontSize: "13px",
                                          position: "relative",
                                          right: "2px",
                                        }}
                                      >
                                        {paymentDetails?.caregiverAddress?.city}
                                        ,{" "}
                                        {
                                          paymentDetails?.caregiverAddress
                                            ?.province
                                        }
                                      </span>
                                    </div>
                                  </Col>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={8}
                                    xs={24}
                                    className="d-flex justify-content-right max-height-auto role-div-mob"
                                    style={{
                                      justifyContent: "right",
                                      maxHeight: "100px",
                                    }}
                                  >
                                    {/* <img className="bookmark-btn" src="/images/icons/bookmark-icon.svg" width={30} height={30} /> */}
                                    <Button
                                      className="role-btn"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      {appointmentData?.paymentDetails
                                        ?.caregiverType == 3
                                        ? "Caregiver"
                                        : appointmentData?.paymentDetails
                                            ?.caregiverType == "4"
                                        ? "Companion"
                                        : ""}
                                    </Button>
                                  </Col>
                                </Row>
                                <Row className="basic-details-row">
                                  <Col
                                    lg={24}
                                    md={4}
                                    sm={24}
                                    xs={24}
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <span className="profile-detail-title">
                                      Booked For
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ alignItems: "center" }}>
                                  <Col
                                    lg={4}
                                    xs={24}
                                    md={16}
                                    className="d-flex align-items-center"
                                  >
                                    {paymentDetails?.relativeImage ? (
                                      <img
                                        alt={""}
                                        style={{ borderRadius: "50px" }}
                                        src={paymentDetails?.relativeImage}
                                        width={40}
                                        height={40}
                                      />
                                    ) : (
                                      <img
                                        alt={""}
                                        style={{ borderRadius: "50px" }}
                                        width={40}
                                        height={40}
                                        src="/images/icons/Profile.png"
                                      />
                                    )}

                                    <div className="mx-2">
                                      <p className="fw-bold mb-1">
                                        {paymentDetails?.relativeName}
                                      </p>
                                    </div>
                                  </Col>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "40px", right: "20px" }}
                                  />
                                  <Col lg={8} xs={24}>
                                    <div className="d-flex address-details-row address-details-confi">
                                      <img
                                        alt={""}
                                        src="/images/icons/location.svg"
                                        width={15}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "11px",
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          lineHeight: "22px",
                                        }}
                                      >
                                        {
                                          paymentDetails?.relativeAddressAddress
                                            ?.address
                                        }
                                        ,{" "}
                                        {
                                          paymentDetails?.relativeAddressAddress
                                            ?.city
                                        }
                                        ,{" "}
                                        {
                                          paymentDetails?.relativeAddressAddress
                                            ?.province
                                        }
                                        ,{" "}
                                        {
                                          paymentDetails?.relativeAddressAddress
                                            ?.postalCode
                                        }
                                      </span>
                                    </div>
                                  </Col>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "40px", right: "20px" }}
                                  />
                                  <Col lg={4} xs={12}>
                                    <div className="d-flex address-details-confi justify-flex-start-mob">
                                      <img
                                        alt={""}
                                        src="/images/icons/time.svg"
                                        width={20}
                                      />
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          lineHeight: "22px",
                                          marginLeft: "11px",
                                        }}
                                      >
                                        {paymentDetails?.totalHour} Hours
                                      </span>
                                    </div>
                                  </Col>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "40px", right: "20px" }}
                                  />
                                  <Col lg={4} xs={12}>
                                    <span className="profile-detail-title">
                                      Hourly Rate
                                    </span>
                                    <p className="profile-detail-val">
                                      ${paymentDetails?.caregiverActualPrice}
                                    </p>
                                  </Col>
                                </Row>
                                <Divider type="horizontal" />
                              </Col>
                              <Col xl={24} lg={24} xs={24} className="mb-3">
                                <span className="profile-detail-title">
                                  Services
                                </span>
                                <Row className="mt-3 mt-mob-0 service-appointment-row">
                                  {paymentDetails?.serviceList.map((i) => {
                                    return (
                                      <Col
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-2 service-appointment-col"
                                      >
                                        <div className="w-100 d-flex service-offer-card">
                                          <img
                                            alt={""}
                                            src={i.image}
                                            width={40}
                                            height={40}
                                          />
                                          <span className="service-offer-text">
                                            {i?.name}
                                          </span>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                              <Col xl={24} lg={24} xs={24}>
                                <span className="profile-detail-title">
                                  Date and Time
                                </span>
                                <Row className="mt-3 mt-mob-0 service-appointment-row">
                                  {paymentDetails.timing.map((i) => {
                                    return (
                                      <Col
                                        lg={3}
                                        md={6}
                                        sm={8}
                                        xs={12}
                                        className="service-appointment-col mt-2"
                                      >
                                        <div className="w-100 d-flex service-offer-card">
                                          <div className="date-card">
                                            {moment(i.date).format("MMM")}{" "}
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {moment(i.date)
                                                .utc()
                                                .format("DD")}
                                            </span>
                                          </div>
                                          <span className="time-text">
                                            {moment(i.startTime)
                                              .zone(0)
                                              .format("hh:mm a")}{" "}
                                            to{" "}
                                            {moment(i.endTime)
                                              .zone(0)
                                              .format("hh:mm a")}
                                          </span>
                                          {/* <span className="service-offer-text">{i?.name}</span> */}
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                              <Col xl={24} lg={24} xs={24} className="mt-3">
                                <span className="profile-detail-title">
                                  Additional Comments
                                </span>
                                <Row className="mt-3 mb-3 mt-mob-0">
                                  <span className="profile-detail-title">
                                    {
                                      paymentDetails?.additionalNote
                                        ?.additionComments
                                    }
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Spin>
                  </ScrollWrapper>
                  <div
                    className="d-flex justify-content-right text-right px-3 mt-4"
                    style={{ justifyContent: "end" }}
                  >
                    <Button
                      onClick={() => navigate("/client/appointments-dashboard")}
                      className="btn-black auth-action-btn mt-3 mb-3"
                      shape="round"
                      type="primary"
                      size="large"
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ImageModal
          imagePopupVisible={imagePopupVisible}
          handleClosePopup={() => setImagePopupVisible(false)}
          caregiverImage={paymentDetails?.caregiverImage}
        />
        <BookingCompletedModal
          bookingCompletionVisible={bookingCompletionVisible}
          handleClose={() => setBookingVisible(false)}
        />
      </>
      {/*}*/}

      {/* <RatingModal
                reviewModalVisible={reviewModalVisible}
                handleRatingModalClose={handleRatingModalClose}
            /> */}
    </div>
  );
}
