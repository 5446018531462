import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Radio } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
import {
  extendBooking,
  paymentDetails,
} from "../../../../../redux/appointment/appointment.action";

const ExtendModal = (props) => {
  const { extendModalVisible, handleClose, appointmentDetails } = props;
  const timing = appointmentDetails?.timing?.[0];
  const startTime = moment(timing?.startTime).utc().format("hh:mm a");
  const endTime = moment(timing?.endTime).utc().format("hh:mm a");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const token = useSelector((state) => state.loginData.user.accessToken);
  const [slotList, setSlotList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getSlots = () => {
    const slotArr = [];
    [1, 2, 3, 4].forEach((num) => {
      const newSlot = moment(timing?.endTime).add(num, "hours");
      slotArr.push(newSlot);
    });
    return slotArr;
  };
  console.log(appointmentDetails, "selectedSlot");
  useEffect(() => {
    getSlotList();
  }, []);
  const getSlotList = () => {
    if (token !== "") {
      const payload = {
        appointmentId: appointmentDetails?._id,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-extended-availability`,
          payload,
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("responsehk", response);
          if (response.data.status === 200) {
            setSlotList(response.data.output);
            // setLoadingProfile(false);
          } else if (response.data.status >= 400) {
            // setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            // setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          //   setLoadingProfile(false);
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const handleExtendAppointment = () => {
    if (token !== "") {
      const payload = {
        appointmentId: appointmentDetails?._id,
        endTime:
          moment(selectedSlot).utc().format("YYYY-MM-DD") +
          "T" +
          moment(selectedSlot).utc().format("HH") +
          ":00:00.000Z",
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/extend-appointment`,
          payload,
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            // setLoadingProfile(false);
            dispatch(extendBooking(response.data.output.bookingDetails));
            navigate("/client/extend-appointment/pay-for-appointment");
          } else if (response.data.status >= 400) {
            // setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            // setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          //   setLoadingProfile(false);
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  return (
    <div className="modal-view">
      <Modal
        className="infoview-modal-container-custom"
        centered
        width={500}
        // style={{maxWidth:'75vw !important'}}
        header={null}
        footer={null}
        open={extendModalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        closable
        closeIcon={<CloseCircleOutlined />}
      >
        <h3
          className="text-center"
          style={{ fontSize: "28px", fontWeight: 600 }}
        >
          Select Hours
        </h3>
        <p
          className="text-center mt-3 mb-2"
          style={{
            background: "#ebedfa",
            color: "#4255ca",
            padding: "15px 0",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "18.58px",
          }}
        >
          Scheduled for:{" "}
          {moment(appointmentDetails?.startTime).format("DD MMMM")}, {startTime}{" "}
          to {endTime}
        </p>
        <div className="custom-modal-extend">
            <p
          className="text-center"
          style={{ fontSize: "20px", lineHeight: "40px",color:'#64748B' }}
        >
          Select time you want to extend service till
        </p>
        <div>
          <Radio.Group className="custom-checkbox"
            value={moment(selectedSlot).utc().format("hh")}
            onChange={(e) => {
              const foundItem = slotList.find(
                (item) => moment(item).utc().format("hh") === e.target.value
              );
              setSelectedSlot(foundItem);
              setSelectedIndex(e.target.id + 1);
            }}
          >
            {slotList.map((item, idx) => {
              return (
                <Radio.Button value={moment(item).utc().format("hh")} id={idx}>
                  {moment(item).utc().format("hh: a")}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
        <p
          style={{ fontSize: "20px", lineHeight: "40px" }}
          className="color-secondary text-center"
        >
          Extending for: <span style={{color:'#364BC6'}}>0{selectedIndex} hours</span>
        </p>
        <Button
          // htmlType="submit"
          onClick={handleExtendAppointment}
          className="btn-black mx-auto auth-action-btn mt-3 mb-3"
          shape="round"
          type="primary"
          size="large"
          disabled={!selectedSlot}
        >
          Done
        </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ExtendModal;
