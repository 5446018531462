import React, { useState, useEffect } from "react";
import UI from "./UI/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomePage from "./common/Welcome";
import ClientLogin from "./pages/client/login";
import Verify from "./pages/client/verify";
import ClientSignup from "./pages/client/signup";
import ForgetPassword from "./pages/client/forget-password";
import EmailSent from "./common/EmailSuccessPopup";
import CareGiverLogin from "./pages/caregiver/login";
import CareGiverSignup from "./pages/caregiver/signup";
import CareGiverVerify from "./pages/caregiver/verify";
import NoMatch from "./pages/noMatch";
import { removeAlert } from "./redux/alert/alert.action";
import { connect, useDispatch, useSelector } from "react-redux";
import Dashboard from "./components/dashboard";
import ClientProfile from "./pages/client/profile";
import CaregiverProfile from "./pages/caregiver/settings/edit-profile-update/caregiverProfile";
import ResetPasswordSuccess from "./pages/client/reset-password-success";
import CaregiverResetPassword from "./pages/caregiver/forget-password";
import CaregiverResetPasswordSuccess from "./pages/caregiver/reset-password-success";
import CaregiverDetails from "./pages/caregiver/details";
import AddAvailability from "./pages/caregiver/Availability";
import Card from "./pages/client/profile/card";
import { Offline } from "react-detect-offline";
import { Modal } from "antd";
import Appointment from "./pages/client/appointment";
import ChooseRelatives from "./components/appointments/choose-relatives";
import ChooseServices from "./pages/client/appointment/choose-services";
import ConfirmRelative from "./components/appointments/confirm-relative";
import "./App.less";
import ChooseDates from "./pages/client/appointment/choose-dates";
import { toggleConfirmRelativeModal } from "./redux/modals/toggleModal.actions";
import ChooseCareGivers from "./pages/client/appointment/choose-caregivers";
import CareGiverDetails from "./pages/client/appointment/careGiverDetails";
import PaymentListScreen from "./pages/client/appointment/paymentListScreen";
import PrivatePath from "./common/helper/PrivatePath";
import BookingConfirmation from "./pages/client/appointment/bookingConfirmationScreen";
import AppointmentsDashboard from "./pages/caregiver/appointment/appointmentsDashboard";
import AppointmentDetails from "./pages/caregiver/appointment/appointmentDetails";
import CaregiverAppointmentDetails from "./pages/caregiver/appointment/caregiverAppointmentDetails";
import Settings from "./pages/caregiver/settings";
import ClientSettings from "./pages/client/settings";
import MyReviews from "./components/caregiver/MyReviews";
import MyCaregivers from "./pages/client/appointment/myCaregivers";
import MyCaregiversSelectDates from "./pages/client/appointment/myCaregivers-select-dates";
import Earnings from "./pages/caregiver/Earnings";
import RelativesList from "./pages/client/relatives/relativesList";
import AddRelatives from "./pages/client/profile/add-relatives";
import AddNewRelative from "./pages/client/relatives/addNewRelative";
import Expenses from "./pages/client/Expenses/Expenses";
import RelativeDetails from "./pages/client/relatives/viewRelativeDetails";
import Message from "./pages/client/messages/messages";
import ContactUs from "./common/ContactUs";
import CaregiverDashboard from "./pages/caregiver/CaregiverDashboard";
import EditAvailablity from "./pages/caregiver/editAvailability";
import ClientMessage from "./pages/client/messages/clientMessage";
import CaregiverMessage from "./pages/client/messages/caregiverMessage";
import CaregiverMessages from "./pages/client/messages/caregiverMessages";
import ViewTutorial from "./pages/client/viewtutorial";
import ViewTutorialCaregiver from "./pages/caregiver/viewtutorial";
import ChangePassword from "./components/settings/changepassword";
import BankDetails from "./pages/client/settings/bankDetails";
import ClientProfileSettings from "./pages/client/settings/clientProfile";
import BankDetailsUpdate from "./pages/caregiver/settings/bank-details-update/BankDetailsUpdate";
import DeleteAccountScreen from "./common/DeleteAccount";
// import TermsAndConditions from "./components/termsandconditions"
// import PrivacyPolicy from "./components/privacypolicy"

import Schedule from "./pages/client/Schedule/Schedule";
// import { socket } from "./redux/socket/socket.reducer";
import {
  handleSocketConnection,
  handleTwilioConnection,
} from "./redux/socket/socket.action";
import * as io from "socket.io-client";
import MobileSchedule from "./pages/client/Schedule/MobileSchedule";
// import { socket } from "./redux/socket/socket.reducer";
// import { socket } from "./redux/socket/socket.reducer";
import "./responsive.css";
import { setUnreadMsgCount, setUser } from "./redux/auth/auth.action";
import { Client } from "@twilio/conversations";
import commonApi from "./http/commonApi";
import WelcomeLogin from "./common/WelcomeLogin";
import RedirectModal from "./components/appointments/RedirectModal";
import ContactRoleSelection from "./common/Contact-role-selection";
import StripeVerify from "./pages/caregiver/StripeVerify";
import StepTutorial from "./pages/caregiver/stepTutorial";
import ExtendPaymentList from "./pages/caregiver/appointment/extension/client/paymentList";
import ExtendBookingConfirmation from "./pages/caregiver/appointment/extension/client/extendBookingConfirmation";
// import ContactRoleSelection from "./common/Contact-role-selection";
const token = localStorage.getItem("token");
const App = ({
  accessToken,
  user,
  chooseRelativeModal,
  confirmRelativeModal,
  redirectAppModal,
}) => {
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState();
  const [userInfo, setUserInfo] = useState();
  const [isOnline, setIsOnline] = useState(true);
  const [caregiverLoc, setCaregiverLoc] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const isSocketConnected = useSelector(
    (state) => state.socketData?.socketConnection
  );
  const newSocketInst = useSelector((state) => state.socketData?.socket);
  const userData = useSelector((state) => state.loginData?.user);
  const userMode = useSelector((state) => state.loginData?.userMode);
  // const [currentConversation, setCurrentConversation] = useState(null);
  // const [isClientConnected, setIsClientConnected] = useState(false);
  const isClientConnected = useSelector(
    (state) => state?.twillioData?.twillioConnection
  );
  const clientDataTwilio = useSelector(
    (state) => state?.twillioData?.twillioClient
  );
  useEffect(() => {
    setAuthenticated(accessToken === "" ? false : true);
    setUserInfo(user);
  }, [accessToken, user, authenticated, userInfo]);

  useEffect(() => {
    if (token && !isSocketConnected) {
      const socket = io(
        `${process.env.REACT_APP_SOCKET_URL}?accessToken=${token}`
      );
      socket.on("connect", () => {
        dispatch(
          handleSocketConnection({
            socketConnection: socket.connected,
            socket: socket,
          })
        );
      });
    }
    // return () => {
    //   dispatch(handleSocketConnection({socketConnection: false, socket: null}));
    // }
  }, [token]);

  useEffect(() => {
    if (userData?.twilioConversationToken) {
      const client = new Client(userData?.twilioConversationToken);
      if (client?.connectionState != "connected") {
        client.on("initialized", () => {
          // debugger;
          // Use the client.(client)
          // setIsClientConnected(client?.connectionState);
          dispatch(
            handleTwilioConnection({
              twillioClient: client,
              twillioConnection: client?.connectionState,
            })
          );
        });
      }
    }
  }, [
    userData?.twilioConversationToken,
    clientDataTwilio?.connectionState,
    isClientConnected,
  ]);

  // useEffect(async () => {
  //   if (
  //     isClientConnected &&
  //     clientDataTwilio &&
  //     clientDataTwilio?.connectionState == "connected"
  //   ) {
  //     clientDataTwilio.on("tokenAboutToExpire", async () => {
  //       console.log("tokenexpiring");
  //       const resp = await commonApi._getTwillioAccessToken();
  //       console.log(resp, "responsetoken");
  //       const twillioConversationToken = resp.output?.twilioConversationToken;
  //       const newUserData = userData;
  //       newUserData["twilioConversationToken"] = twillioConversationToken;
  //       dispatch(setUser(newUserData));
  //     });
  //   }
  // }, [clientDataTwilio?.connectionState, isClientConnected]);
  const getLatLng = (position) => {
    const crd = position.coords;
    setCaregiverLoc({ lat: `${crd.latitude}`, lng: `${crd.longitude}` });
  };

  useEffect(() => {
    if (
      accessToken &&
      isSocketConnected &&
      newSocketInst &&
      userMode == "caregiver"
    ) {
      const intervalId = setInterval(() => {
        navigator.geolocation.getCurrentPosition(getLatLng);
        if (
          newSocketInst?.connected &&
          caregiverLoc?.lat &&
          caregiverLoc?.lng
        ) {
          newSocketInst.emit("updateLocation", {
            userId: userData?.userId,
            lat: caregiverLoc?.lat,
            lon: caregiverLoc?.lng,
          });
        }
      }, 10000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [accessToken, isSocketConnected, userMode, caregiverLoc]);

  useEffect(() => {
    let intervalId;
    if (clientDataTwilio && clientDataTwilio?.connectionState == "connected") {
      intervalId = setInterval(() => {
        const conversations = clientDataTwilio.getSubscribedConversations();
        conversations.then(async (Resp) => {
          for (const elem of Resp.items) {
            const unreadCount = await elem.getUnreadMessagesCount();
            if (unreadCount > 0) {
              dispatch(setUnreadMsgCount(unreadCount));
              // return;
            }
          }
        });
      }, 7000);
      // newSocketInst.on("chatListFromServer", (data) => {
      //   console.log(data, "data");
      //   dispatch(setUnreadMsgCount(data.unReadCount));
      // });

      // newSocketInst.on("messageFromServer", (data) => {
      //   newSocketInst.emit("chatList", {
      //     senderId: userData?.userId,
      //     pageNo: 1,
      //     limit: 10,
      //   });
      // });
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [clientDataTwilio]);
  const error = () => {
    Modal.error({
      title: "you are offline",
      content: "please check your internet connnection.",
    });
  };

  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, [navigator.onLine]);

  return (
    <>
      <Router>
        {/* <Offline> */}
        {!isOnline && (
          <div
            className="text-center"
            style={{
              height: "40px",
              width: "100%",
              background: "rgb(255 0 0 / 62%)",
              color: "#fff",
              fontWeight: "600",
            }}
          >
            <p className="pt-2">
              {" "}
              You are offline! please check your connection.{" "}
            </p>
          </div>
        )}
        {/* </Offline> */}
        {/* <button onClick={() => dispatch(toggleConfirmRelativeModal(true)) }>open</button> */}
        {chooseRelativeModal && <ChooseRelatives />}
        {confirmRelativeModal && <ConfirmRelative />}
        {redirectAppModal && <RedirectModal />}
        <Routes>
          <Route path="/" element={<WelcomePage />} exact />
          <Route path="/role-selection" element={<WelcomeLogin />} exact />
          <Route
            path="/support-role-select"
            element={<ContactRoleSelection />}
            exact
          />
          <Route path="/client/signin" element={<ClientLogin />} exact />
          <Route path="/client/signup" element={<ClientSignup />} exact />
          <Route path="/client/settings" element={<ClientSettings />} exact />
          <Route path="/client/verify" element={<Verify />} exact />
          <Route
            path="/client/forget-password"
            element={<ForgetPassword />}
            exact
          />
          <Route
            path="/client/reset-password/success"
            element={<ResetPasswordSuccess />}
            exact
          />
          <Route path="/client/email-sent" element={<EmailSent />} exact />
          <Route path="/caregiver/signin" element={<CareGiverLogin />} exact />
          <Route path="/caregiver/signup" element={<CareGiverSignup />} exact />
          <Route
            path="/caregiver/delete-account"
            element={<DeleteAccountScreen />}
            exact
          />
          <Route path="/caregiver/verify" element={<CareGiverVerify />} exact />
          <Route path="/caregiver/email-sent" element={<EmailSent />} exact />
          <Route
            path="/caregiver/forget-password"
            element={<CaregiverResetPassword />}
            exact
          />
          <Route
            path="/caregiver/reset-password/success"
            element={<CaregiverResetPasswordSuccess />}
            exact
          />
          <Route
            path="/caregiver/details"
            element={
              <PrivatePath>
                <CaregiverDetails />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/stripe-verify"
            element={
              <PrivatePath>
                <StripeVerify />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/add-availability"
            element={
              <PrivatePath>
                <AddAvailability />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/edit-availability"
            element={
              <PrivatePath>
                <EditAvailablity accessToken={token} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/dashboard/my-reviews"
            element={
              <PrivatePath>
                <MyReviews />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/dashboard/my-caregivers"
            element={
              <PrivatePath>
                <MyCaregivers />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/my-caregivers/services"
            element={
              <PrivatePath>
                <ChooseServices isBookmarked={true} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/my-caregivers/view-caregiver/:id"
            element={
              <PrivatePath>
                <CareGiverDetails isBookmarked={true} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/my-caregivers/select-time-slots"
            element={
              <PrivatePath>
                <MyCaregiversSelectDates />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment"
            element={
              <PrivatePath>
                <Appointment />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointments-dashboard"
            element={
              <PrivatePath>
                <AppointmentsDashboard />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/appointments-dashboard"
            element={
              <PrivatePath>
                <AppointmentsDashboard />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/dashboard"
            element={
              <PrivatePath>
                <CaregiverDashboard />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/create-appointment/services"
            element={
              <PrivatePath>
                <ChooseServices />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/create-appointment/dates"
            element={
              <PrivatePath>
                <ChooseDates />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/create-appointment/caregivers"
            element={
              <PrivatePath>
                <ChooseCareGivers />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/create-appointment/view-caregiver/:id"
            element={
              <PrivatePath>
                <CareGiverDetails />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/pay-for-appointment"
            element={
              <PrivatePath>
                <PaymentListScreen />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/extend-appointment/pay-for-appointment"
            element={
              <PrivatePath>
                <ExtendPaymentList />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/my-caregivers/appointment/pay-for-appointment"
            element={
              <PrivatePath>
                <PaymentListScreen isBookmarked={true} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/booking-confirmation"
            element={
              <PrivatePath>
                <BookingConfirmation />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/extend-appointment/booking-confirmation"
            element={
              <PrivatePath>
                <ExtendBookingConfirmation />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/appointment-details/:id"
            element={
              <PrivatePath>
                <AppointmentDetails />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/relatives"
            element={
              <PrivatePath>
                <RelativesList />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/relatives/:id"
            element={
              <PrivatePath>
                <AddNewRelative />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/relatives/view-relative/:id"
            element={
              <PrivatePath>
                <RelativeDetails />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/relatives/add-new-relative"
            element={
              <PrivatePath>
                <AddNewRelative ADDING_NEW />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/messages"
            element={
              <PrivatePath>
                <Message />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/messages"
            element={
              <PrivatePath>
                <CaregiverMessages />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/appointment/messages"
            element={
              <PrivatePath>
                <ClientMessage isClientConnected={isClientConnected} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/appointment/messages"
            element={
              <PrivatePath>
                <CaregiverMessage />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/appointment/appointment-details/:id"
            element={
              <PrivatePath>
                <CaregiverAppointmentDetails caregiverLoc={caregiverLoc} />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/demo-ui"
            element={
              <PrivatePath>
                <UI />
              </PrivatePath>
            }
          />
          <Route
            element={
              <PrivatePath>
                <ClientProfile />
              </PrivatePath>
            }
            path="/client/profile"
            exact
          />
          <Route
            element={
              <PrivatePath>
                <Dashboard />
              </PrivatePath>
            }
            path="/dashboard"
          />
          <Route
            element={
              <PrivatePath>
                <CaregiverProfile />
              </PrivatePath>
            }
            path="/caregiver/profile"
            exact
          />
          {/* <Route element={<PrivatePath><Dashboard /></PrivatePath>} path="/caregiver/dashboard" /> */}
          <Route
            path="/caregiver/settings"
            element={
              <PrivatePath>
                <Settings />
              </PrivatePath>
            }
          />
          <Route
            element={
              <PrivatePath>
                <Card />
              </PrivatePath>
            }
            path="/card"
          />
          <Route
            path="/caregiver/earnings"
            element={
              <PrivatePath>
                <Earnings />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/expenses"
            element={
              <PrivatePath>
                <Expenses />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/expenses"
            element={
              <PrivatePath>
                <Expenses />
              </PrivatePath>
            }
            exact
          />
          <Route path="/client/contactus" element={<ContactUs />} exact />
          <Route path="/caregiver/contactus" element={<ContactUs />} exact />
          <Route
            path="/client/view-tutorials"
            element={<ViewTutorial />}
            exact
          />
          <Route
            path="/client/view-tutorials"
            element={<ViewTutorial />}
            exact
          />
          <Route
            path="/caregiver/view-tutorials"
            element={<ViewTutorialCaregiver />}
            exact
          />
          <Route path="/caregiver/tutorials" element={<StepTutorial />} exact />
          <Route
            path="/client/settings/editprofile"
            element={
              <PrivatePath>
                <ClientProfileSettings />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/settings/changepassword"
            element={
              <PrivatePath>
                <ChangePassword />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/settings/bankinformation"
            element={
              <PrivatePath>
                <BankDetails />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/settings/editprofile"
            element={
              <PrivatePath>
                <CaregiverProfile />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/settings/changepassword"
            element={
              <PrivatePath>
                <ChangePassword />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/settings/bankinformation"
            element={
              <PrivatePath>
                <BankDetailsUpdate />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/my-schedule"
            element={
              <PrivatePath>
                <Schedule />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/my-schedule"
            element={
              <PrivatePath>
                <Schedule />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/caregiver/schedule"
            element={
              <PrivatePath>
                <MobileSchedule />
              </PrivatePath>
            }
            exact
          />
          <Route
            path="/client/schedule"
            element={
              <PrivatePath>
                <MobileSchedule />
              </PrivatePath>
            }
            exact
          />
          {/* <Route path="/client/termsandcondition" element={<TermsAndConditions />} exact />
        <Route path="/client/privacypolicy" element={<PrivacyPolicy />} exact />
        <Route path="/caregiver/termsandcondition" element={<TermsAndConditions />} exact />
        <Route path="/caregiver/privacypolicy" element={<PrivacyPolicy />} exact /> */}
          <Route
            path="*"
            element={
              <PrivatePath>
                <NoMatch />
              </PrivatePath>
            }
          ></Route>
        </Routes>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    chooseRelativeModal: state.toggleModals?.chooseRelativeModal,
    confirmRelativeModal: state.toggleModals?.confirmRelativeModal,
    redirectAppModal: state.toggleModals?.redirectAppModal,
    alert: state?.alert,
    userType: state.loginData?.user?.userType,
    screen: state.loginData?.user?.screen,
    accessToken: state.loginData?.user?.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeAlert: () => dispatch(removeAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
